/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/CreateSubAccount', 'model/CreateSubAccountResponse', 'model/ErrorModel', 'model/GetSsoToken', 'model/MasterDetailsResponse', 'model/SsoTokenRequest', 'model/SubAccountDetailsResponse', 'model/SubAccountUpdatePlanRequest', 'model/SubAccountsResponse'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/CreateSubAccount'), require('../model/CreateSubAccountResponse'), require('../model/ErrorModel'), require('../model/GetSsoToken'), require('../model/MasterDetailsResponse'), require('../model/SsoTokenRequest'), require('../model/SubAccountDetailsResponse'), require('../model/SubAccountUpdatePlanRequest'), require('../model/SubAccountsResponse'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.MasterAccountApi = factory(root.SibApiV3Sdk.ApiClient, root.SibApiV3Sdk.CreateSubAccount, root.SibApiV3Sdk.CreateSubAccountResponse, root.SibApiV3Sdk.ErrorModel, root.SibApiV3Sdk.GetSsoToken, root.SibApiV3Sdk.MasterDetailsResponse, root.SibApiV3Sdk.SsoTokenRequest, root.SibApiV3Sdk.SubAccountDetailsResponse, root.SibApiV3Sdk.SubAccountUpdatePlanRequest, root.SibApiV3Sdk.SubAccountsResponse);
  }
}(this, function(ApiClient, CreateSubAccount, CreateSubAccountResponse, ErrorModel, GetSsoToken, MasterDetailsResponse, SsoTokenRequest, SubAccountDetailsResponse, SubAccountUpdatePlanRequest, SubAccountsResponse) {
  'use strict';

  /**
   * MasterAccount service.
   * @module api/MasterAccountApi
   * @version 8.5.0
   */

  /**
   * Constructs a new MasterAccountApi. 
   * @alias module:api/MasterAccountApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * Get the details of requested master account
     * This endpoint will provide the details of the master account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/MasterDetailsResponse} and HTTP response
     */
    this.corporateMasterAccountGetWithHttpInfo = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = MasterDetailsResponse;

      return this.apiClient.callApi(
        '/corporate/masterAccount', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the details of requested master account
     * This endpoint will provide the details of the master account.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/MasterDetailsResponse}
     */
    this.corporateMasterAccountGet = function() {
      return this.corporateMasterAccountGetWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the list of all the sub-accounts of the master account.
     * This endpoint will provide the list all the sub-accounts of the master account.
     * @param {Number} offset Index of the first sub-account in the page
     * @param {Number} limit Number of sub-accounts to be displayed on each page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubAccountsResponse} and HTTP response
     */
    this.corporateSubAccountGetWithHttpInfo = function(offset, limit) {
      var postBody = null;

      // verify the required parameter 'offset' is set
      if (offset === undefined || offset === null) {
        throw new Error("Missing the required parameter 'offset' when calling corporateSubAccountGet");
      }

      // verify the required parameter 'limit' is set
      if (limit === undefined || limit === null) {
        throw new Error("Missing the required parameter 'limit' when calling corporateSubAccountGet");
      }


      var pathParams = {
      };
      var queryParams = {
        'offset': offset,
        'limit': limit,
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = SubAccountsResponse;

      return this.apiClient.callApi(
        '/corporate/subAccount', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the list of all the sub-accounts of the master account.
     * This endpoint will provide the list all the sub-accounts of the master account.
     * @param {Number} offset Index of the first sub-account in the page
     * @param {Number} limit Number of sub-accounts to be displayed on each page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubAccountsResponse}
     */
    this.corporateSubAccountGet = function(offset, limit) {
      return this.corporateSubAccountGetWithHttpInfo(offset, limit)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a sub-account
     * @param {Number} id Id of the sub-account organization to be deleted
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.corporateSubAccountIdDeleteWithHttpInfo = function(id) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling corporateSubAccountIdDelete");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/corporate/subAccount/{id}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a sub-account
     * @param {Number} id Id of the sub-account organization to be deleted
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.corporateSubAccountIdDelete = function(id) {
      return this.corporateSubAccountIdDeleteWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get sub-account details
     * This endpoint will provide the details for the specified sub-account company
     * @param {Number} id Id of the sub-account organization
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubAccountDetailsResponse} and HTTP response
     */
    this.corporateSubAccountIdGetWithHttpInfo = function(id) {
      var postBody = null;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling corporateSubAccountIdGet");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = SubAccountDetailsResponse;

      return this.apiClient.callApi(
        '/corporate/subAccount/{id}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get sub-account details
     * This endpoint will provide the details for the specified sub-account company
     * @param {Number} id Id of the sub-account organization
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubAccountDetailsResponse}
     */
    this.corporateSubAccountIdGet = function(id) {
      return this.corporateSubAccountIdGetWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update sub-account plan
     * This endpoint will update the sub-account plan
     * @param {Number} id Id of the sub-account organization
     * @param {module:model/SubAccountUpdatePlanRequest} updatePlanDetails Values to update a sub-account plan
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.corporateSubAccountIdPlanPutWithHttpInfo = function(id, updatePlanDetails) {
      var postBody = updatePlanDetails;

      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling corporateSubAccountIdPlanPut");
      }

      // verify the required parameter 'updatePlanDetails' is set
      if (updatePlanDetails === undefined || updatePlanDetails === null) {
        throw new Error("Missing the required parameter 'updatePlanDetails' when calling corporateSubAccountIdPlanPut");
      }


      var pathParams = {
        'id': id
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/corporate/subAccount/{id}/plan', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update sub-account plan
     * This endpoint will update the sub-account plan
     * @param {Number} id Id of the sub-account organization
     * @param {module:model/SubAccountUpdatePlanRequest} updatePlanDetails Values to update a sub-account plan
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.corporateSubAccountIdPlanPut = function(id, updatePlanDetails) {
      return this.corporateSubAccountIdPlanPutWithHttpInfo(id, updatePlanDetails)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a new sub-account under a master account.
     * This endpoint will create a new sub-account under a master account
     * @param {module:model/CreateSubAccount} subAccountCreate values to create new sub-account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreateSubAccountResponse} and HTTP response
     */
    this.corporateSubAccountPostWithHttpInfo = function(subAccountCreate) {
      var postBody = subAccountCreate;

      // verify the required parameter 'subAccountCreate' is set
      if (subAccountCreate === undefined || subAccountCreate === null) {
        throw new Error("Missing the required parameter 'subAccountCreate' when calling corporateSubAccountPost");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = CreateSubAccountResponse;

      return this.apiClient.callApi(
        '/corporate/subAccount', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a new sub-account under a master account.
     * This endpoint will create a new sub-account under a master account
     * @param {module:model/CreateSubAccount} subAccountCreate values to create new sub-account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreateSubAccountResponse}
     */
    this.corporateSubAccountPost = function(subAccountCreate) {
      return this.corporateSubAccountPostWithHttpInfo(subAccountCreate)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Generate SSO token to access Sendinblue
     * This endpoint generates an sso token to authenticate and access a sub-account of the master using the account endpoint https://account-app.sendinblue.com/account/login/sub-account/sso/[token], where [token] will be replaced by the actual token.
     * @param {module:model/SsoTokenRequest} ssoTokenRequest Values to generate SSO token for sub-account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetSsoToken} and HTTP response
     */
    this.corporateSubAccountSsoTokenPostWithHttpInfo = function(ssoTokenRequest) {
      var postBody = ssoTokenRequest;

      // verify the required parameter 'ssoTokenRequest' is set
      if (ssoTokenRequest === undefined || ssoTokenRequest === null) {
        throw new Error("Missing the required parameter 'ssoTokenRequest' when calling corporateSubAccountSsoTokenPost");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetSsoToken;

      return this.apiClient.callApi(
        '/corporate/subAccount/ssoToken', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Generate SSO token to access Sendinblue
     * This endpoint generates an sso token to authenticate and access a sub-account of the master using the account endpoint https://account-app.sendinblue.com/account/login/sub-account/sso/[token], where [token] will be replaced by the actual token.
     * @param {module:model/SsoTokenRequest} ssoTokenRequest Values to generate SSO token for sub-account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetSsoToken}
     */
    this.corporateSubAccountSsoTokenPost = function(ssoTokenRequest) {
      return this.corporateSubAccountSsoTokenPostWithHttpInfo(ssoTokenRequest)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
  };

  return exports;
}));
