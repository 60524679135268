/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/CreateSender', 'model/CreateSenderModel', 'model/ErrorModel', 'model/GetIps', 'model/GetIpsFromSender', 'model/GetSendersList', 'model/UpdateSender'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/CreateSender'), require('../model/CreateSenderModel'), require('../model/ErrorModel'), require('../model/GetIps'), require('../model/GetIpsFromSender'), require('../model/GetSendersList'), require('../model/UpdateSender'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.SendersApi = factory(root.SibApiV3Sdk.ApiClient, root.SibApiV3Sdk.CreateSender, root.SibApiV3Sdk.CreateSenderModel, root.SibApiV3Sdk.ErrorModel, root.SibApiV3Sdk.GetIps, root.SibApiV3Sdk.GetIpsFromSender, root.SibApiV3Sdk.GetSendersList, root.SibApiV3Sdk.UpdateSender);
  }
}(this, function(ApiClient, CreateSender, CreateSenderModel, ErrorModel, GetIps, GetIpsFromSender, GetSendersList, UpdateSender) {
  'use strict';

  /**
   * Senders service.
   * @module api/SendersApi
   * @version 8.5.0
   */

  /**
   * Constructs a new SendersApi. 
   * @alias module:api/SendersApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * Create a new sender
     * @param {Object} opts Optional parameters
     * @param {module:model/CreateSender} opts.sender sender's name
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreateSenderModel} and HTTP response
     */
    this.createSenderWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = opts['sender'];


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = CreateSenderModel;

      return this.apiClient.callApi(
        '/senders', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a new sender
     * @param {Object} opts Optional parameters
     * @param {module:model/CreateSender} opts.sender sender's name
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreateSenderModel}
     */
    this.createSender = function(opts) {
      return this.createSenderWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a sender
     * @param {Number} senderId Id of the sender
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.deleteSenderWithHttpInfo = function(senderId) {
      var postBody = null;

      // verify the required parameter 'senderId' is set
      if (senderId === undefined || senderId === null) {
        throw new Error("Missing the required parameter 'senderId' when calling deleteSender");
      }


      var pathParams = {
        'senderId': senderId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/senders/{senderId}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a sender
     * @param {Number} senderId Id of the sender
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.deleteSender = function(senderId) {
      return this.deleteSenderWithHttpInfo(senderId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the dedicated IPs for your account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetIps} and HTTP response
     */
    this.getIpsWithHttpInfo = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetIps;

      return this.apiClient.callApi(
        '/senders/ips', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the dedicated IPs for your account
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetIps}
     */
    this.getIps = function() {
      return this.getIpsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the dedicated IPs for a sender
     * @param {Number} senderId Id of the sender
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetIpsFromSender} and HTTP response
     */
    this.getIpsFromSenderWithHttpInfo = function(senderId) {
      var postBody = null;

      // verify the required parameter 'senderId' is set
      if (senderId === undefined || senderId === null) {
        throw new Error("Missing the required parameter 'senderId' when calling getIpsFromSender");
      }


      var pathParams = {
        'senderId': senderId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetIpsFromSender;

      return this.apiClient.callApi(
        '/senders/{senderId}/ips', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the dedicated IPs for a sender
     * @param {Number} senderId Id of the sender
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetIpsFromSender}
     */
    this.getIpsFromSender = function(senderId) {
      return this.getIpsFromSenderWithHttpInfo(senderId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the list of all your senders
     * @param {Object} opts Optional parameters
     * @param {String} opts.ip Filter your senders for a specific ip (available for dedicated IP usage only)
     * @param {String} opts.domain Filter your senders for a specific domain
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetSendersList} and HTTP response
     */
    this.getSendersWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'ip': opts['ip'],
        'domain': opts['domain'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetSendersList;

      return this.apiClient.callApi(
        '/senders', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the list of all your senders
     * @param {Object} opts Optional parameters
     * @param {String} opts.ip Filter your senders for a specific ip (available for dedicated IP usage only)
     * @param {String} opts.domain Filter your senders for a specific domain
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetSendersList}
     */
    this.getSenders = function(opts) {
      return this.getSendersWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a sender
     * @param {Number} senderId Id of the sender
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateSender} opts.sender sender's name
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.updateSenderWithHttpInfo = function(senderId, opts) {
      opts = opts || {};
      var postBody = opts['sender'];

      // verify the required parameter 'senderId' is set
      if (senderId === undefined || senderId === null) {
        throw new Error("Missing the required parameter 'senderId' when calling updateSender");
      }


      var pathParams = {
        'senderId': senderId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/senders/{senderId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a sender
     * @param {Number} senderId Id of the sender
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateSender} opts.sender sender's name
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.updateSender = function(senderId, opts) {
      return this.updateSenderWithHttpInfo(senderId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
  };

  return exports;
}));
