/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.TaskReminder = factory(root.SibApiV3Sdk.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';

  /**
   * The TaskReminder model module.
   * @module model/TaskReminder
   * @version 8.5.0
   */

  /**
   * Constructs a new <code>TaskReminder</code>.
   * Task reminder date/time for a task
   * @alias module:model/TaskReminder
   * @class
   * @param value {Number} Value of time unit before reminder is to be sent
   * @param unit {module:model/TaskReminder.UnitEnum} Unit of time before reminder is to be sent
   * @param types {Array.<String>} Type of task reminder e.g email, push
   */
  var exports = function(value, unit, types) {
    this.value = value;
    this.unit = unit;
    this.types = types;
  };

  /**
   * Constructs a <code>TaskReminder</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/TaskReminder} obj Optional instance to populate.
   * @return {module:model/TaskReminder} The populated <code>TaskReminder</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();
      if (data.hasOwnProperty('value'))
        obj.value = ApiClient.convertToType(data['value'], 'Number');
      if (data.hasOwnProperty('unit'))
        obj.unit = ApiClient.convertToType(data['unit'], 'String');
      if (data.hasOwnProperty('types'))
        obj.types = ApiClient.convertToType(data['types'], ['String']);
    }
    return obj;
  }

  /**
   * Value of time unit before reminder is to be sent
   * @member {Number} value
   */
  exports.prototype.value = undefined;

  /**
   * Unit of time before reminder is to be sent
   * @member {module:model/TaskReminder.UnitEnum} unit
   */
  exports.prototype.unit = undefined;

  /**
   * Type of task reminder e.g email, push
   * @member {Array.<String>} types
   */
  exports.prototype.types = undefined;



  /**
   * Allowed values for the <code>unit</code> property.
   * @enum {String}
   * @readonly
   */
  exports.UnitEnum = {
    /**
     * value: "minutes"
     * @const
     */
    minutes: "minutes",

    /**
     * value: "hours"
     * @const
     */
    hours: "hours",

    /**
     * value: "weeks"
     * @const
     */
    weeks: "weeks",

    /**
     * value: "days"
     * @const
     */
    days: "days"
  };

  return exports;

}));
