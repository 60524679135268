/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/SendSmtpEmailBcc', 'model/SendSmtpEmailCc', 'model/SendSmtpEmailReplyTo1', 'model/SendSmtpEmailTo1'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./SendSmtpEmailBcc'), require('./SendSmtpEmailCc'), require('./SendSmtpEmailReplyTo1'), require('./SendSmtpEmailTo1'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.SendSmtpEmailMessageVersions = factory(root.SibApiV3Sdk.ApiClient, root.SibApiV3Sdk.SendSmtpEmailBcc, root.SibApiV3Sdk.SendSmtpEmailCc, root.SibApiV3Sdk.SendSmtpEmailReplyTo1, root.SibApiV3Sdk.SendSmtpEmailTo1);
  }
}(this, function(ApiClient, SendSmtpEmailBcc, SendSmtpEmailCc, SendSmtpEmailReplyTo1, SendSmtpEmailTo1) {
  'use strict';

  /**
   * The SendSmtpEmailMessageVersions model module.
   * @module model/SendSmtpEmailMessageVersions
   * @version 8.5.0
   */

  /**
   * Constructs a new <code>SendSmtpEmailMessageVersions</code>.
   * @alias module:model/SendSmtpEmailMessageVersions
   * @class
   * @param to {Array.<module:model/SendSmtpEmailTo1>} List of email addresses and names (_optional_) of the recipients. For example, [{\"name\":\"Jimmy\", \"email\":\"jimmy98@example.com\"}, {\"name\":\"Joe\", \"email\":\"joe@example.com\"}]
   */
  var exports = function(to) {
    this.to = to;
  };

  /**
   * Constructs a <code>SendSmtpEmailMessageVersions</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SendSmtpEmailMessageVersions} obj Optional instance to populate.
   * @return {module:model/SendSmtpEmailMessageVersions} The populated <code>SendSmtpEmailMessageVersions</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();
      if (data.hasOwnProperty('to'))
        obj.to = ApiClient.convertToType(data['to'], [SendSmtpEmailTo1]);
      if (data.hasOwnProperty('params'))
        obj.params = ApiClient.convertToType(data['params'], {'String': Object});
      if (data.hasOwnProperty('bcc'))
        obj.bcc = ApiClient.convertToType(data['bcc'], [SendSmtpEmailBcc]);
      if (data.hasOwnProperty('cc'))
        obj.cc = ApiClient.convertToType(data['cc'], [SendSmtpEmailCc]);
      if (data.hasOwnProperty('replyTo'))
        obj.replyTo = SendSmtpEmailReplyTo1.constructFromObject(data['replyTo']);
      if (data.hasOwnProperty('subject'))
        obj.subject = ApiClient.convertToType(data['subject'], 'String');
    }
    return obj;
  }

  /**
   * List of email addresses and names (_optional_) of the recipients. For example, [{\"name\":\"Jimmy\", \"email\":\"jimmy98@example.com\"}, {\"name\":\"Joe\", \"email\":\"joe@example.com\"}]
   * @member {Array.<module:model/SendSmtpEmailTo1>} to
   */
  exports.prototype.to = undefined;

  /**
   * Pass the set of attributes to customize the template. For example, {\"FNAME\":\"Joe\", \"LNAME\":\"Doe\"}. It's considered only if template is in New Template Language format.
   * @member {Object.<String, Object>} params
   */
  exports.prototype.params = undefined;

  /**
   * List of email addresses and names (optional) of the recipients in bcc
   * @member {Array.<module:model/SendSmtpEmailBcc>} bcc
   */
  exports.prototype.bcc = undefined;

  /**
   * List of email addresses and names (optional) of the recipients in cc
   * @member {Array.<module:model/SendSmtpEmailCc>} cc
   */
  exports.prototype.cc = undefined;

  /**
   * @member {module:model/SendSmtpEmailReplyTo1} replyTo
   */
  exports.prototype.replyTo = undefined;

  /**
   * Custom subject specific to message version 
   * @member {String} subject
   */
  exports.prototype.subject = undefined;


  return exports;

}));
