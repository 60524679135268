/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/BlockDomain', 'model/CreateModel', 'model/CreateSmtpEmail', 'model/CreateSmtpTemplate', 'model/DeleteHardbounces', 'model/ErrorModel', 'model/GetAggregatedReport', 'model/GetBlockedDomains', 'model/GetEmailEventReport', 'model/GetReports', 'model/GetScheduledEmailByBatchId', 'model/GetScheduledEmailByMessageId', 'model/GetSmtpTemplateOverview', 'model/GetSmtpTemplates', 'model/GetTransacBlockedContacts', 'model/GetTransacEmailContent', 'model/GetTransacEmailsList', 'model/PostSendFailed', 'model/ScheduleSmtpEmail', 'model/SendSmtpEmail', 'model/SendTestEmail', 'model/UpdateSmtpTemplate'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/BlockDomain'), require('../model/CreateModel'), require('../model/CreateSmtpEmail'), require('../model/CreateSmtpTemplate'), require('../model/DeleteHardbounces'), require('../model/ErrorModel'), require('../model/GetAggregatedReport'), require('../model/GetBlockedDomains'), require('../model/GetEmailEventReport'), require('../model/GetReports'), require('../model/GetScheduledEmailByBatchId'), require('../model/GetScheduledEmailByMessageId'), require('../model/GetSmtpTemplateOverview'), require('../model/GetSmtpTemplates'), require('../model/GetTransacBlockedContacts'), require('../model/GetTransacEmailContent'), require('../model/GetTransacEmailsList'), require('../model/PostSendFailed'), require('../model/ScheduleSmtpEmail'), require('../model/SendSmtpEmail'), require('../model/SendTestEmail'), require('../model/UpdateSmtpTemplate'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.TransactionalEmailsApi = factory(root.SibApiV3Sdk.ApiClient, root.SibApiV3Sdk.BlockDomain, root.SibApiV3Sdk.CreateModel, root.SibApiV3Sdk.CreateSmtpEmail, root.SibApiV3Sdk.CreateSmtpTemplate, root.SibApiV3Sdk.DeleteHardbounces, root.SibApiV3Sdk.ErrorModel, root.SibApiV3Sdk.GetAggregatedReport, root.SibApiV3Sdk.GetBlockedDomains, root.SibApiV3Sdk.GetEmailEventReport, root.SibApiV3Sdk.GetReports, root.SibApiV3Sdk.GetScheduledEmailByBatchId, root.SibApiV3Sdk.GetScheduledEmailByMessageId, root.SibApiV3Sdk.GetSmtpTemplateOverview, root.SibApiV3Sdk.GetSmtpTemplates, root.SibApiV3Sdk.GetTransacBlockedContacts, root.SibApiV3Sdk.GetTransacEmailContent, root.SibApiV3Sdk.GetTransacEmailsList, root.SibApiV3Sdk.PostSendFailed, root.SibApiV3Sdk.ScheduleSmtpEmail, root.SibApiV3Sdk.SendSmtpEmail, root.SibApiV3Sdk.SendTestEmail, root.SibApiV3Sdk.UpdateSmtpTemplate);
  }
}(this, function(ApiClient, BlockDomain, CreateModel, CreateSmtpEmail, CreateSmtpTemplate, DeleteHardbounces, ErrorModel, GetAggregatedReport, GetBlockedDomains, GetEmailEventReport, GetReports, GetScheduledEmailByBatchId, GetScheduledEmailByMessageId, GetSmtpTemplateOverview, GetSmtpTemplates, GetTransacBlockedContacts, GetTransacEmailContent, GetTransacEmailsList, PostSendFailed, ScheduleSmtpEmail, SendSmtpEmail, SendTestEmail, UpdateSmtpTemplate) {
  'use strict';

  /**
   * TransactionalEmails service.
   * @module api/TransactionalEmailsApi
   * @version 8.5.0
   */

  /**
   * Constructs a new TransactionalEmailsApi. 
   * @alias module:api/TransactionalEmailsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * Add a new domain to the list of blocked domains
     * Blocks a new domain in order to avoid messages being sent to the same
     * @param {module:model/BlockDomain} blockDomain 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.blockNewDomainWithHttpInfo = function(blockDomain) {
      var postBody = blockDomain;

      // verify the required parameter 'blockDomain' is set
      if (blockDomain === undefined || blockDomain === null) {
        throw new Error("Missing the required parameter 'blockDomain' when calling blockNewDomain");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/smtp/blockedDomains', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add a new domain to the list of blocked domains
     * Blocks a new domain in order to avoid messages being sent to the same
     * @param {module:model/BlockDomain} blockDomain 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.blockNewDomain = function(blockDomain) {
      return this.blockNewDomainWithHttpInfo(blockDomain)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create an email template
     * @param {module:model/CreateSmtpTemplate} smtpTemplate values to update in transactional email template
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreateModel} and HTTP response
     */
    this.createSmtpTemplateWithHttpInfo = function(smtpTemplate) {
      var postBody = smtpTemplate;

      // verify the required parameter 'smtpTemplate' is set
      if (smtpTemplate === undefined || smtpTemplate === null) {
        throw new Error("Missing the required parameter 'smtpTemplate' when calling createSmtpTemplate");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = CreateModel;

      return this.apiClient.callApi(
        '/smtp/templates', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create an email template
     * @param {module:model/CreateSmtpTemplate} smtpTemplate values to update in transactional email template
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreateModel}
     */
    this.createSmtpTemplate = function(smtpTemplate) {
      return this.createSmtpTemplateWithHttpInfo(smtpTemplate)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Unblock an existing domain from the list of blocked domains
     * Unblocks an existing domain from the list of blocked domains
     * @param {String} domain The name of the domain to be deleted
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.deleteBlockedDomainWithHttpInfo = function(domain) {
      var postBody = null;

      // verify the required parameter 'domain' is set
      if (domain === undefined || domain === null) {
        throw new Error("Missing the required parameter 'domain' when calling deleteBlockedDomain");
      }


      var pathParams = {
        'domain': domain
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/smtp/blockedDomains/{domain}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Unblock an existing domain from the list of blocked domains
     * Unblocks an existing domain from the list of blocked domains
     * @param {String} domain The name of the domain to be deleted
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.deleteBlockedDomain = function(domain) {
      return this.deleteBlockedDomainWithHttpInfo(domain)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete hardbounces
     * Delete hardbounces. To use carefully (e.g. in case of temporary ISP failures)
     * @param {Object} opts Optional parameters
     * @param {module:model/DeleteHardbounces} opts.deleteHardbounces values to delete hardbounces
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.deleteHardbouncesWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = opts['deleteHardbounces'];


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/smtp/deleteHardbounces', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete hardbounces
     * Delete hardbounces. To use carefully (e.g. in case of temporary ISP failures)
     * @param {Object} opts Optional parameters
     * @param {module:model/DeleteHardbounces} opts.deleteHardbounces values to delete hardbounces
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.deleteHardbounces = function(opts) {
      return this.deleteHardbouncesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete scheduled emails by batchId or messageId
     * Delete scheduled batch of emails by batchId or single scheduled email by messageId
     * @param {String} identifier The `batchId` of scheduled emails batch (Should be a valid UUIDv4) or the `messageId` of scheduled email.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.deleteScheduledEmailByIdWithHttpInfo = function(identifier) {
      var postBody = null;

      // verify the required parameter 'identifier' is set
      if (identifier === undefined || identifier === null) {
        throw new Error("Missing the required parameter 'identifier' when calling deleteScheduledEmailById");
      }


      var pathParams = {
        'identifier': identifier
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/smtp/email/{identifier}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete scheduled emails by batchId or messageId
     * Delete scheduled batch of emails by batchId or single scheduled email by messageId
     * @param {String} identifier The `batchId` of scheduled emails batch (Should be a valid UUIDv4) or the `messageId` of scheduled email.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.deleteScheduledEmailById = function(identifier) {
      return this.deleteScheduledEmailByIdWithHttpInfo(identifier)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete an inactive email template
     * @param {Number} templateId id of the template
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.deleteSmtpTemplateWithHttpInfo = function(templateId) {
      var postBody = null;

      // verify the required parameter 'templateId' is set
      if (templateId === undefined || templateId === null) {
        throw new Error("Missing the required parameter 'templateId' when calling deleteSmtpTemplate");
      }


      var pathParams = {
        'templateId': templateId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/smtp/templates/{templateId}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete an inactive email template
     * @param {Number} templateId id of the template
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.deleteSmtpTemplate = function(templateId) {
      return this.deleteSmtpTemplateWithHttpInfo(templateId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get your transactional email activity aggregated over a period of time
     * This endpoint will show the aggregated stats for past 90 days by default if `startDate` and `endDate` OR `days` is not passed. The date range can not exceed 90 days
     * @param {Object} opts Optional parameters
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date of the report (YYYY-MM-DD). Must be lower than equal to endDate
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date of the report (YYYY-MM-DD). Must be greater than equal to startDate
     * @param {Number} opts.days Number of days in the past including today (positive integer). Not compatible with 'startDate' and 'endDate'
     * @param {String} opts.tag Tag of the emails
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetAggregatedReport} and HTTP response
     */
    this.getAggregatedSmtpReportWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'startDate': opts['startDate'],
        'endDate': opts['endDate'],
        'days': opts['days'],
        'tag': opts['tag'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetAggregatedReport;

      return this.apiClient.callApi(
        '/smtp/statistics/aggregatedReport', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get your transactional email activity aggregated over a period of time
     * This endpoint will show the aggregated stats for past 90 days by default if `startDate` and `endDate` OR `days` is not passed. The date range can not exceed 90 days
     * @param {Object} opts Optional parameters
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date of the report (YYYY-MM-DD). Must be lower than equal to endDate
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date of the report (YYYY-MM-DD). Must be greater than equal to startDate
     * @param {Number} opts.days Number of days in the past including today (positive integer). Not compatible with 'startDate' and 'endDate'
     * @param {String} opts.tag Tag of the emails
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetAggregatedReport}
     */
    this.getAggregatedSmtpReport = function(opts) {
      return this.getAggregatedSmtpReportWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the list of blocked domains
     * Get the list of blocked domains
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetBlockedDomains} and HTTP response
     */
    this.getBlockedDomainsWithHttpInfo = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetBlockedDomains;

      return this.apiClient.callApi(
        '/smtp/blockedDomains', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the list of blocked domains
     * Get the list of blocked domains
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetBlockedDomains}
     */
    this.getBlockedDomains = function() {
      return this.getBlockedDomainsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all your transactional email activity (unaggregated events)
     * This endpoint will show the aggregated stats for past 30 days by default if `startDate` and `endDate` OR `days` is not passed. The date range can not exceed 90 days
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number limitation for the result returned (default to 2500)
     * @param {Number} opts.offset Beginning point in the list to retrieve from. (default to 0)
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date of the report (YYYY-MM-DD). Must be lower than equal to endDate
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date of the report (YYYY-MM-DD). Must be greater than equal to startDate
     * @param {Number} opts.days Number of days in the past including today (positive integer). Not compatible with 'startDate' and 'endDate'
     * @param {String} opts.email Filter the report for a specific email addresses
     * @param {module:model/String} opts.event Filter the report for a specific event type
     * @param {String} opts.tags Filter the report for tags (serialized and urlencoded array)
     * @param {String} opts.messageId Filter on a specific message id
     * @param {Number} opts.templateId Filter on a specific template id
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetEmailEventReport} and HTTP response
     */
    this.getEmailEventReportWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'startDate': opts['startDate'],
        'endDate': opts['endDate'],
        'days': opts['days'],
        'email': opts['email'],
        'event': opts['event'],
        'tags': opts['tags'],
        'messageId': opts['messageId'],
        'templateId': opts['templateId'],
        'sort': opts['sort'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetEmailEventReport;

      return this.apiClient.callApi(
        '/smtp/statistics/events', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all your transactional email activity (unaggregated events)
     * This endpoint will show the aggregated stats for past 30 days by default if `startDate` and `endDate` OR `days` is not passed. The date range can not exceed 90 days
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number limitation for the result returned (default to 2500)
     * @param {Number} opts.offset Beginning point in the list to retrieve from. (default to 0)
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date of the report (YYYY-MM-DD). Must be lower than equal to endDate
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date of the report (YYYY-MM-DD). Must be greater than equal to startDate
     * @param {Number} opts.days Number of days in the past including today (positive integer). Not compatible with 'startDate' and 'endDate'
     * @param {String} opts.email Filter the report for a specific email addresses
     * @param {module:model/String} opts.event Filter the report for a specific event type
     * @param {String} opts.tags Filter the report for tags (serialized and urlencoded array)
     * @param {String} opts.messageId Filter on a specific message id
     * @param {Number} opts.templateId Filter on a specific template id
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetEmailEventReport}
     */
    this.getEmailEventReport = function(opts) {
      return this.getEmailEventReportWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fetch scheduled emails by batchId
     * Fetch scheduled batch of emails by batchId (Can retrieve data upto 30 days old)
     * @param {String} batchId The batchId of scheduled emails batch (Should be a valid UUIDv4)
     * @param {Object} opts Optional parameters
     * @param {Date} opts.startDate Mandatory if `endDate` is used. Starting date (YYYY-MM-DD) from which you want to fetch the list. Can be maximum 30 days older tha current date.
     * @param {Date} opts.endDate Mandatory if `startDate` is used. Ending date (YYYY-MM-DD) till which you want to fetch the list. Maximum time period that can be selected is one month.
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @param {module:model/String} opts.status Filter the records by `status` of the scheduled email batch or message.
     * @param {Number} opts.limit Number of documents returned per page (default to 100)
     * @param {Number} opts.offset Index of the first document on the page (default to 0)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetScheduledEmailByBatchId} and HTTP response
     */
    this.getScheduledEmailByBatchIdWithHttpInfo = function(batchId, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'batchId' is set
      if (batchId === undefined || batchId === null) {
        throw new Error("Missing the required parameter 'batchId' when calling getScheduledEmailByBatchId");
      }


      var pathParams = {
        'batchId': batchId
      };
      var queryParams = {
        'startDate': opts['startDate'],
        'endDate': opts['endDate'],
        'sort': opts['sort'],
        'status': opts['status'],
        'limit': opts['limit'],
        'offset': opts['offset'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetScheduledEmailByBatchId;

      return this.apiClient.callApi(
        '/smtp/emailStatus/{batchId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Fetch scheduled emails by batchId
     * Fetch scheduled batch of emails by batchId (Can retrieve data upto 30 days old)
     * @param {String} batchId The batchId of scheduled emails batch (Should be a valid UUIDv4)
     * @param {Object} opts Optional parameters
     * @param {Date} opts.startDate Mandatory if `endDate` is used. Starting date (YYYY-MM-DD) from which you want to fetch the list. Can be maximum 30 days older tha current date.
     * @param {Date} opts.endDate Mandatory if `startDate` is used. Ending date (YYYY-MM-DD) till which you want to fetch the list. Maximum time period that can be selected is one month.
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @param {module:model/String} opts.status Filter the records by `status` of the scheduled email batch or message.
     * @param {Number} opts.limit Number of documents returned per page (default to 100)
     * @param {Number} opts.offset Index of the first document on the page (default to 0)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetScheduledEmailByBatchId}
     */
    this.getScheduledEmailByBatchId = function(batchId, opts) {
      return this.getScheduledEmailByBatchIdWithHttpInfo(batchId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fetch scheduled email by messageId
     * Fetch scheduled email by messageId (Can retrieve data upto 30 days old)
     * @param {String} messageId The messageId of scheduled email
     * @param {Object} opts Optional parameters
     * @param {Date} opts.startDate Mandatory if endDate is used. Starting date (YYYY-MM-DD) from which you want to fetch the list. Can be maximum 30 days older tha current date.
     * @param {Date} opts.endDate Mandatory if startDate is used. Ending date (YYYY-MM-DD) till which you want to fetch the list. Maximum time period that can be selected is one month.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetScheduledEmailByMessageId} and HTTP response
     */
    this.getScheduledEmailByMessageIdWithHttpInfo = function(messageId, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'messageId' is set
      if (messageId === undefined || messageId === null) {
        throw new Error("Missing the required parameter 'messageId' when calling getScheduledEmailByMessageId");
      }


      var pathParams = {
        'messageId': messageId
      };
      var queryParams = {
        'startDate': opts['startDate'],
        'endDate': opts['endDate'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetScheduledEmailByMessageId;

      return this.apiClient.callApi(
        '/smtp/emailStatus/{messageId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Fetch scheduled email by messageId
     * Fetch scheduled email by messageId (Can retrieve data upto 30 days old)
     * @param {String} messageId The messageId of scheduled email
     * @param {Object} opts Optional parameters
     * @param {Date} opts.startDate Mandatory if endDate is used. Starting date (YYYY-MM-DD) from which you want to fetch the list. Can be maximum 30 days older tha current date.
     * @param {Date} opts.endDate Mandatory if startDate is used. Ending date (YYYY-MM-DD) till which you want to fetch the list. Maximum time period that can be selected is one month.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetScheduledEmailByMessageId}
     */
    this.getScheduledEmailByMessageId = function(messageId, opts) {
      return this.getScheduledEmailByMessageIdWithHttpInfo(messageId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get your transactional email activity aggregated per day
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of documents returned per page (default to 10)
     * @param {Number} opts.offset Index of the first document on the page (default to 0)
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date of the report (YYYY-MM-DD)
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date of the report (YYYY-MM-DD)
     * @param {Number} opts.days Number of days in the past including today (positive integer). Not compatible with 'startDate' and 'endDate'
     * @param {String} opts.tag Tag of the emails
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetReports} and HTTP response
     */
    this.getSmtpReportWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'startDate': opts['startDate'],
        'endDate': opts['endDate'],
        'days': opts['days'],
        'tag': opts['tag'],
        'sort': opts['sort'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetReports;

      return this.apiClient.callApi(
        '/smtp/statistics/reports', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get your transactional email activity aggregated per day
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of documents returned per page (default to 10)
     * @param {Number} opts.offset Index of the first document on the page (default to 0)
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date of the report (YYYY-MM-DD)
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date of the report (YYYY-MM-DD)
     * @param {Number} opts.days Number of days in the past including today (positive integer). Not compatible with 'startDate' and 'endDate'
     * @param {String} opts.tag Tag of the emails
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetReports}
     */
    this.getSmtpReport = function(opts) {
      return this.getSmtpReportWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns the template information
     * @param {Number} templateId id of the template
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetSmtpTemplateOverview} and HTTP response
     */
    this.getSmtpTemplateWithHttpInfo = function(templateId) {
      var postBody = null;

      // verify the required parameter 'templateId' is set
      if (templateId === undefined || templateId === null) {
        throw new Error("Missing the required parameter 'templateId' when calling getSmtpTemplate");
      }


      var pathParams = {
        'templateId': templateId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetSmtpTemplateOverview;

      return this.apiClient.callApi(
        '/smtp/templates/{templateId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns the template information
     * @param {Number} templateId id of the template
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetSmtpTemplateOverview}
     */
    this.getSmtpTemplate = function(templateId) {
      return this.getSmtpTemplateWithHttpInfo(templateId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the list of email templates
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.templateStatus Filter on the status of the template. Active = true, inactive = false
     * @param {Number} opts.limit Number of documents returned per page (default to 50)
     * @param {Number} opts.offset Index of the first document in the page (default to 0)
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetSmtpTemplates} and HTTP response
     */
    this.getSmtpTemplatesWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'templateStatus': opts['templateStatus'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'sort': opts['sort'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetSmtpTemplates;

      return this.apiClient.callApi(
        '/smtp/templates', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the list of email templates
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.templateStatus Filter on the status of the template. Active = true, inactive = false
     * @param {Number} opts.limit Number of documents returned per page (default to 50)
     * @param {Number} opts.offset Index of the first document in the page (default to 0)
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetSmtpTemplates}
     */
    this.getSmtpTemplates = function(opts) {
      return this.getSmtpTemplatesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the list of blocked or unsubscribed transactional contacts
     * @param {Object} opts Optional parameters
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date (YYYY-MM-DD) from which you want to fetch the blocked or unsubscribed contacts
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date (YYYY-MM-DD) till which you want to fetch the blocked or unsubscribed contacts
     * @param {Number} opts.limit Number of documents returned per page (default to 50)
     * @param {Number} opts.offset Index of the first document on the page (default to 0)
     * @param {Array.<String>} opts.senders Comma separated list of emails of the senders from which contacts are blocked or unsubscribed
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetTransacBlockedContacts} and HTTP response
     */
    this.getTransacBlockedContactsWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'startDate': opts['startDate'],
        'endDate': opts['endDate'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'sort': opts['sort'],
      };
      var collectionQueryParams = {
        'senders': {
          value: opts['senders'],
          collectionFormat: 'csv'
        },
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetTransacBlockedContacts;

      return this.apiClient.callApi(
        '/smtp/blockedContacts', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the list of blocked or unsubscribed transactional contacts
     * @param {Object} opts Optional parameters
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date (YYYY-MM-DD) from which you want to fetch the blocked or unsubscribed contacts
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date (YYYY-MM-DD) till which you want to fetch the blocked or unsubscribed contacts
     * @param {Number} opts.limit Number of documents returned per page (default to 50)
     * @param {Number} opts.offset Index of the first document on the page (default to 0)
     * @param {Array.<String>} opts.senders Comma separated list of emails of the senders from which contacts are blocked or unsubscribed
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetTransacBlockedContacts}
     */
    this.getTransacBlockedContacts = function(opts) {
      return this.getTransacBlockedContactsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the personalized content of a sent transactional email
     * @param {String} uuid Unique id of the transactional email that has been sent to a particular contact
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetTransacEmailContent} and HTTP response
     */
    this.getTransacEmailContentWithHttpInfo = function(uuid) {
      var postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getTransacEmailContent");
      }


      var pathParams = {
        'uuid': uuid
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetTransacEmailContent;

      return this.apiClient.callApi(
        '/smtp/emails/{uuid}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the personalized content of a sent transactional email
     * @param {String} uuid Unique id of the transactional email that has been sent to a particular contact
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetTransacEmailContent}
     */
    this.getTransacEmailContent = function(uuid) {
      return this.getTransacEmailContentWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get the list of transactional emails on the basis of allowed filters
     * This endpoint will show the list of emails for past 30 days by default. To retrieve emails before that time, please pass startDate and endDate in query filters.
     * @param {Object} opts Optional parameters
     * @param {String} opts.email Mandatory if templateId and messageId are not passed in query filters. Email address to which transactional email has been sent.
     * @param {Number} opts.templateId Mandatory if email and messageId are not passed in query filters. Id of the template that was used to compose transactional email.
     * @param {String} opts.messageId Mandatory if templateId and email are not passed in query filters. Message ID of the transactional email sent.
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date (YYYY-MM-DD) from which you want to fetch the list. Maximum time period that can be selected is one month.
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date (YYYY-MM-DD) till which you want to fetch the list. Maximum time period that can be selected is one month.
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @param {Number} opts.limit Number of documents returned per page (default to 500)
     * @param {Number} opts.offset Index of the first document in the page (default to 0)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetTransacEmailsList} and HTTP response
     */
    this.getTransacEmailsListWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'email': opts['email'],
        'templateId': opts['templateId'],
        'messageId': opts['messageId'],
        'startDate': opts['startDate'],
        'endDate': opts['endDate'],
        'sort': opts['sort'],
        'limit': opts['limit'],
        'offset': opts['offset'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetTransacEmailsList;

      return this.apiClient.callApi(
        '/smtp/emails', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get the list of transactional emails on the basis of allowed filters
     * This endpoint will show the list of emails for past 30 days by default. To retrieve emails before that time, please pass startDate and endDate in query filters.
     * @param {Object} opts Optional parameters
     * @param {String} opts.email Mandatory if templateId and messageId are not passed in query filters. Email address to which transactional email has been sent.
     * @param {Number} opts.templateId Mandatory if email and messageId are not passed in query filters. Id of the template that was used to compose transactional email.
     * @param {String} opts.messageId Mandatory if templateId and email are not passed in query filters. Message ID of the transactional email sent.
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date (YYYY-MM-DD) from which you want to fetch the list. Maximum time period that can be selected is one month.
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date (YYYY-MM-DD) till which you want to fetch the list. Maximum time period that can be selected is one month.
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @param {Number} opts.limit Number of documents returned per page (default to 500)
     * @param {Number} opts.offset Index of the first document in the page (default to 0)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetTransacEmailsList}
     */
    this.getTransacEmailsList = function(opts) {
      return this.getTransacEmailsListWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Send a template to your test list
     * @param {Number} templateId Id of the template
     * @param {module:model/SendTestEmail} sendTestEmail 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.sendTestTemplateWithHttpInfo = function(templateId, sendTestEmail) {
      var postBody = sendTestEmail;

      // verify the required parameter 'templateId' is set
      if (templateId === undefined || templateId === null) {
        throw new Error("Missing the required parameter 'templateId' when calling sendTestTemplate");
      }

      // verify the required parameter 'sendTestEmail' is set
      if (sendTestEmail === undefined || sendTestEmail === null) {
        throw new Error("Missing the required parameter 'sendTestEmail' when calling sendTestTemplate");
      }


      var pathParams = {
        'templateId': templateId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/smtp/templates/{templateId}/sendTest', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Send a template to your test list
     * @param {Number} templateId Id of the template
     * @param {module:model/SendTestEmail} sendTestEmail 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.sendTestTemplate = function(templateId, sendTestEmail) {
      return this.sendTestTemplateWithHttpInfo(templateId, sendTestEmail)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Send a transactional email
     * @param {module:model/SendSmtpEmail} sendSmtpEmail Values to send a transactional email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreateSmtpEmail} and HTTP response
     */
    this.sendTransacEmailWithHttpInfo = function(sendSmtpEmail) {
      var postBody = sendSmtpEmail;

      // verify the required parameter 'sendSmtpEmail' is set
      if (sendSmtpEmail === undefined || sendSmtpEmail === null) {
        throw new Error("Missing the required parameter 'sendSmtpEmail' when calling sendTransacEmail");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = CreateSmtpEmail;

      return this.apiClient.callApi(
        '/smtp/email', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Send a transactional email
     * @param {module:model/SendSmtpEmail} sendSmtpEmail Values to send a transactional email
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreateSmtpEmail}
     */
    this.sendTransacEmail = function(sendSmtpEmail) {
      return this.sendTransacEmailWithHttpInfo(sendSmtpEmail)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Unblock or resubscribe a transactional contact
     * @param {String} email contact email (urlencoded) to unblock.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.smtpBlockedContactsEmailDeleteWithHttpInfo = function(email) {
      var postBody = null;

      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling smtpBlockedContactsEmailDelete");
      }


      var pathParams = {
        'email': email
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/smtp/blockedContacts/{email}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Unblock or resubscribe a transactional contact
     * @param {String} email contact email (urlencoded) to unblock.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.smtpBlockedContactsEmailDelete = function(email) {
      return this.smtpBlockedContactsEmailDeleteWithHttpInfo(email)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete an SMTP transactional log
     * @param {String} messageId MessageId of the transactional log to delete
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.smtpLogMessageIdDeleteWithHttpInfo = function(messageId) {
      var postBody = null;

      // verify the required parameter 'messageId' is set
      if (messageId === undefined || messageId === null) {
        throw new Error("Missing the required parameter 'messageId' when calling smtpLogMessageIdDelete");
      }


      var pathParams = {
        'messageId': messageId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/smtp/log/{messageId}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete an SMTP transactional log
     * @param {String} messageId MessageId of the transactional log to delete
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.smtpLogMessageIdDelete = function(messageId) {
      return this.smtpLogMessageIdDeleteWithHttpInfo(messageId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update an email template
     * @param {Number} templateId id of the template
     * @param {module:model/UpdateSmtpTemplate} smtpTemplate values to update in transactional email template
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.updateSmtpTemplateWithHttpInfo = function(templateId, smtpTemplate) {
      var postBody = smtpTemplate;

      // verify the required parameter 'templateId' is set
      if (templateId === undefined || templateId === null) {
        throw new Error("Missing the required parameter 'templateId' when calling updateSmtpTemplate");
      }

      // verify the required parameter 'smtpTemplate' is set
      if (smtpTemplate === undefined || smtpTemplate === null) {
        throw new Error("Missing the required parameter 'smtpTemplate' when calling updateSmtpTemplate");
      }


      var pathParams = {
        'templateId': templateId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/smtp/templates/{templateId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update an email template
     * @param {Number} templateId id of the template
     * @param {module:model/UpdateSmtpTemplate} smtpTemplate values to update in transactional email template
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.updateSmtpTemplate = function(templateId, smtpTemplate) {
      return this.updateSmtpTemplateWithHttpInfo(templateId, smtpTemplate)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
  };

  return exports;
}));
