/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.Body12 = factory(root.SibApiV3Sdk.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';

  /**
   * The Body12 model module.
   * @module model/Body12
   * @version 8.5.0
   */

  /**
   * Constructs a new <code>Body12</code>.
   * @alias module:model/Body12
   * @class
   */
  var exports = function() {
  };

  /**
   * Constructs a <code>Body12</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Body12} obj Optional instance to populate.
   * @return {module:model/Body12} The populated <code>Body12</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();
      if (data.hasOwnProperty('agentId'))
        obj.agentId = ApiClient.convertToType(data['agentId'], 'String');
      if (data.hasOwnProperty('receivedFrom'))
        obj.receivedFrom = ApiClient.convertToType(data['receivedFrom'], 'String');
      if (data.hasOwnProperty('agentEmail'))
        obj.agentEmail = ApiClient.convertToType(data['agentEmail'], 'String');
      if (data.hasOwnProperty('agentName'))
        obj.agentName = ApiClient.convertToType(data['agentName'], 'String');
    }
    return obj;
  }

  /**
   * agent ID. It can be found on agent’s page or received <a href=\"https://developers.sendinblue.com/docs/conversations-webhooks\">from a webhook</a>. Alternatively, you can use `agentEmail` + `agentName` + `receivedFrom` instead (all 3 fields required).
   * @member {String} agentId
   */
  exports.prototype.agentId = undefined;

  /**
   * mark your messages to distinguish messages created by you from the others.
   * @member {String} receivedFrom
   */
  exports.prototype.receivedFrom = undefined;

  /**
   * agent email. When sending online pings from a standalone system, it’s hard to maintain a 1-to-1 relationship between the users of both systems. In this case, an agent can be specified by their email address. If there’s no agent with the specified email address in your Sendinblue organization, a dummy agent will be created automatically.
   * @member {String} agentEmail
   */
  exports.prototype.agentEmail = undefined;

  /**
   * agent name.
   * @member {String} agentName
   */
  exports.prototype.agentName = undefined;


  return exports;

}));
