/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.Body10 = factory(root.SibApiV3Sdk.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';

  /**
   * The Body10 model module.
   * @module model/Body10
   * @version 8.5.0
   */

  /**
   * Constructs a new <code>Body10</code>.
   * @alias module:model/Body10
   * @class
   * @param visitorId {String} visitor’s ID received <a href=\"https://developers.sendinblue.com/docs/conversations-webhooks\">from a webhook</a> or generated by you to <a href=\"https://developers.sendinblue.com/docs/customize-the-widget#identifying-existing-users\">bind existing user account to Conversations</a>
   * @param text {String} message text
   */
  var exports = function(visitorId, text) {
    this.visitorId = visitorId;
    this.text = text;
  };

  /**
   * Constructs a <code>Body10</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Body10} obj Optional instance to populate.
   * @return {module:model/Body10} The populated <code>Body10</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();
      if (data.hasOwnProperty('visitorId'))
        obj.visitorId = ApiClient.convertToType(data['visitorId'], 'String');
      if (data.hasOwnProperty('text'))
        obj.text = ApiClient.convertToType(data['text'], 'String');
      if (data.hasOwnProperty('agentId'))
        obj.agentId = ApiClient.convertToType(data['agentId'], 'String');
      if (data.hasOwnProperty('groupId'))
        obj.groupId = ApiClient.convertToType(data['groupId'], 'String');
    }
    return obj;
  }

  /**
   * visitor’s ID received <a href=\"https://developers.sendinblue.com/docs/conversations-webhooks\">from a webhook</a> or generated by you to <a href=\"https://developers.sendinblue.com/docs/customize-the-widget#identifying-existing-users\">bind existing user account to Conversations</a>
   * @member {String} visitorId
   */
  exports.prototype.visitorId = undefined;

  /**
   * message text
   * @member {String} text
   */
  exports.prototype.text = undefined;

  /**
   * agent ID. It can be found on agent’s page or received <a href=\"https://developers.sendinblue.com/docs/conversations-webhooks\">from a webhook</a>. Optional if `groupId` is set.
   * @member {String} agentId
   */
  exports.prototype.agentId = undefined;

  /**
   * group ID. It can be found on group’s page. Optional if `agentId` is set.
   * @member {String} groupId
   */
  exports.prototype.groupId = undefined;


  return exports;

}));
