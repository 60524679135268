/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.SubAccountsResponseSubAccounts = factory(root.SibApiV3Sdk.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';

  /**
   * The SubAccountsResponseSubAccounts model module.
   * @module model/SubAccountsResponseSubAccounts
   * @version 8.5.0
   */

  /**
   * Constructs a new <code>SubAccountsResponseSubAccounts</code>.
   * @alias module:model/SubAccountsResponseSubAccounts
   * @class
   * @param id {Number} id of the sub-account
   * @param companyName {String} Name of the sub-account company
   * @param active {Boolean} Whether the sub-account is active or not
   * @param createdAt {Number} Timestamp when the sub-account was created
   */
  var exports = function(id, companyName, active, createdAt) {
    this.id = id;
    this.companyName = companyName;
    this.active = active;
    this.createdAt = createdAt;
  };

  /**
   * Constructs a <code>SubAccountsResponseSubAccounts</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/SubAccountsResponseSubAccounts} obj Optional instance to populate.
   * @return {module:model/SubAccountsResponseSubAccounts} The populated <code>SubAccountsResponseSubAccounts</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();
      if (data.hasOwnProperty('id'))
        obj.id = ApiClient.convertToType(data['id'], 'Number');
      if (data.hasOwnProperty('companyName'))
        obj.companyName = ApiClient.convertToType(data['companyName'], 'String');
      if (data.hasOwnProperty('active'))
        obj.active = ApiClient.convertToType(data['active'], 'Boolean');
      if (data.hasOwnProperty('createdAt'))
        obj.createdAt = ApiClient.convertToType(data['createdAt'], 'Number');
    }
    return obj;
  }

  /**
   * id of the sub-account
   * @member {Number} id
   */
  exports.prototype.id = undefined;

  /**
   * Name of the sub-account company
   * @member {String} companyName
   */
  exports.prototype.companyName = undefined;

  /**
   * Whether the sub-account is active or not
   * @member {Boolean} active
   */
  exports.prototype.active = undefined;

  /**
   * Timestamp when the sub-account was created
   * @member {Number} createdAt
   */
  exports.prototype.createdAt = undefined;


  return exports;

}));
