/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.GetCampaignStats = factory(root.SibApiV3Sdk.ApiClient);
  }
}(this, function(ApiClient) {
  'use strict';

  /**
   * The GetCampaignStats model module.
   * @module model/GetCampaignStats
   * @version 8.5.0
   */

  /**
   * Constructs a new <code>GetCampaignStats</code>.
   * @alias module:model/GetCampaignStats
   * @class
   * @param uniqueClicks {Number} Number of unique clicks for the campaign
   * @param clickers {Number} Number of total clicks for the campaign
   * @param complaints {Number} Number of complaints (Spam reports) for the campaign
   * @param delivered {Number} Number of delivered emails for the campaign
   * @param sent {Number} Number of sent emails for the campaign
   * @param softBounces {Number} Number of softbounce for the campaign
   * @param hardBounces {Number} Number of harbounce for the campaign
   * @param uniqueViews {Number} Number of unique openings for the campaign
   * @param trackableViews {Number} Recipients without any privacy protection option enabled in their email client
   * @param unsubscriptions {Number} Number of unsubscription for the campaign
   * @param viewed {Number} Number of openings for the campaign
   */
  var exports = function(uniqueClicks, clickers, complaints, delivered, sent, softBounces, hardBounces, uniqueViews, trackableViews, unsubscriptions, viewed) {
    this.uniqueClicks = uniqueClicks;
    this.clickers = clickers;
    this.complaints = complaints;
    this.delivered = delivered;
    this.sent = sent;
    this.softBounces = softBounces;
    this.hardBounces = hardBounces;
    this.uniqueViews = uniqueViews;
    this.trackableViews = trackableViews;
    this.unsubscriptions = unsubscriptions;
    this.viewed = viewed;
  };

  /**
   * Constructs a <code>GetCampaignStats</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GetCampaignStats} obj Optional instance to populate.
   * @return {module:model/GetCampaignStats} The populated <code>GetCampaignStats</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();
      if (data.hasOwnProperty('listId'))
        obj.listId = ApiClient.convertToType(data['listId'], 'Number');
      if (data.hasOwnProperty('uniqueClicks'))
        obj.uniqueClicks = ApiClient.convertToType(data['uniqueClicks'], 'Number');
      if (data.hasOwnProperty('clickers'))
        obj.clickers = ApiClient.convertToType(data['clickers'], 'Number');
      if (data.hasOwnProperty('complaints'))
        obj.complaints = ApiClient.convertToType(data['complaints'], 'Number');
      if (data.hasOwnProperty('delivered'))
        obj.delivered = ApiClient.convertToType(data['delivered'], 'Number');
      if (data.hasOwnProperty('sent'))
        obj.sent = ApiClient.convertToType(data['sent'], 'Number');
      if (data.hasOwnProperty('softBounces'))
        obj.softBounces = ApiClient.convertToType(data['softBounces'], 'Number');
      if (data.hasOwnProperty('hardBounces'))
        obj.hardBounces = ApiClient.convertToType(data['hardBounces'], 'Number');
      if (data.hasOwnProperty('uniqueViews'))
        obj.uniqueViews = ApiClient.convertToType(data['uniqueViews'], 'Number');
      if (data.hasOwnProperty('trackableViews'))
        obj.trackableViews = ApiClient.convertToType(data['trackableViews'], 'Number');
      if (data.hasOwnProperty('trackableViewsRate'))
        obj.trackableViewsRate = ApiClient.convertToType(data['trackableViewsRate'], 'Number');
      if (data.hasOwnProperty('estimatedViews'))
        obj.estimatedViews = ApiClient.convertToType(data['estimatedViews'], 'Number');
      if (data.hasOwnProperty('unsubscriptions'))
        obj.unsubscriptions = ApiClient.convertToType(data['unsubscriptions'], 'Number');
      if (data.hasOwnProperty('viewed'))
        obj.viewed = ApiClient.convertToType(data['viewed'], 'Number');
      if (data.hasOwnProperty('deferred'))
        obj.deferred = ApiClient.convertToType(data['deferred'], 'Number');
      if (data.hasOwnProperty('returnBounce'))
        obj.returnBounce = ApiClient.convertToType(data['returnBounce'], 'Number');
    }
    return obj;
  }

  /**
   * List Id of email campaign (only in case of get email campaign(s)(not for global stats))
   * @member {Number} listId
   */
  exports.prototype.listId = undefined;

  /**
   * Number of unique clicks for the campaign
   * @member {Number} uniqueClicks
   */
  exports.prototype.uniqueClicks = undefined;

  /**
   * Number of total clicks for the campaign
   * @member {Number} clickers
   */
  exports.prototype.clickers = undefined;

  /**
   * Number of complaints (Spam reports) for the campaign
   * @member {Number} complaints
   */
  exports.prototype.complaints = undefined;

  /**
   * Number of delivered emails for the campaign
   * @member {Number} delivered
   */
  exports.prototype.delivered = undefined;

  /**
   * Number of sent emails for the campaign
   * @member {Number} sent
   */
  exports.prototype.sent = undefined;

  /**
   * Number of softbounce for the campaign
   * @member {Number} softBounces
   */
  exports.prototype.softBounces = undefined;

  /**
   * Number of harbounce for the campaign
   * @member {Number} hardBounces
   */
  exports.prototype.hardBounces = undefined;

  /**
   * Number of unique openings for the campaign
   * @member {Number} uniqueViews
   */
  exports.prototype.uniqueViews = undefined;

  /**
   * Recipients without any privacy protection option enabled in their email client
   * @member {Number} trackableViews
   */
  exports.prototype.trackableViews = undefined;

  /**
   * Rate of recipients without any privacy protection option enabled in their email client
   * @member {Number} trackableViewsRate
   */
  exports.prototype.trackableViewsRate = undefined;

  /**
   * Rate of recipients without any privacy protection option enabled in their email client, applied to all delivered emails
   * @member {Number} estimatedViews
   */
  exports.prototype.estimatedViews = undefined;

  /**
   * Number of unsubscription for the campaign
   * @member {Number} unsubscriptions
   */
  exports.prototype.unsubscriptions = undefined;

  /**
   * Number of openings for the campaign
   * @member {Number} viewed
   */
  exports.prototype.viewed = undefined;

  /**
   * Number of deferred emails for the campaign
   * @member {Number} deferred
   */
  exports.prototype.deferred = undefined;

  /**
   * Total number of non-delivered campaigns for a particular campaign id.
   * @member {Number} returnBounce
   */
  exports.prototype.returnBounce = undefined;


  return exports;

}));
