/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/AbTestVersionStats'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./AbTestVersionStats'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.AbTestCampaignResultStatistics = factory(root.SibApiV3Sdk.ApiClient, root.SibApiV3Sdk.AbTestVersionStats);
  }
}(this, function(ApiClient, AbTestVersionStats) {
  'use strict';

  /**
   * The AbTestCampaignResultStatistics model module.
   * @module model/AbTestCampaignResultStatistics
   * @version 8.5.0
   */

  /**
   * Constructs a new <code>AbTestCampaignResultStatistics</code>.
   * @alias module:model/AbTestCampaignResultStatistics
   * @class
   * @param openers {module:model/AbTestVersionStats} 
   * @param clicks {module:model/AbTestVersionStats} 
   * @param unsubscribed {module:model/AbTestVersionStats} 
   * @param hardBounces {module:model/AbTestVersionStats} 
   * @param softBounces {module:model/AbTestVersionStats} 
   * @param complaints {module:model/AbTestVersionStats} 
   */
  var exports = function(openers, clicks, unsubscribed, hardBounces, softBounces, complaints) {
    this.openers = openers;
    this.clicks = clicks;
    this.unsubscribed = unsubscribed;
    this.hardBounces = hardBounces;
    this.softBounces = softBounces;
    this.complaints = complaints;
  };

  /**
   * Constructs a <code>AbTestCampaignResultStatistics</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AbTestCampaignResultStatistics} obj Optional instance to populate.
   * @return {module:model/AbTestCampaignResultStatistics} The populated <code>AbTestCampaignResultStatistics</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();
      if (data.hasOwnProperty('openers'))
        obj.openers = AbTestVersionStats.constructFromObject(data['openers']);
      if (data.hasOwnProperty('clicks'))
        obj.clicks = AbTestVersionStats.constructFromObject(data['clicks']);
      if (data.hasOwnProperty('unsubscribed'))
        obj.unsubscribed = AbTestVersionStats.constructFromObject(data['unsubscribed']);
      if (data.hasOwnProperty('hardBounces'))
        obj.hardBounces = AbTestVersionStats.constructFromObject(data['hardBounces']);
      if (data.hasOwnProperty('softBounces'))
        obj.softBounces = AbTestVersionStats.constructFromObject(data['softBounces']);
      if (data.hasOwnProperty('complaints'))
        obj.complaints = AbTestVersionStats.constructFromObject(data['complaints']);
    }
    return obj;
  }

  /**
   * @member {module:model/AbTestVersionStats} openers
   */
  exports.prototype.openers = undefined;

  /**
   * @member {module:model/AbTestVersionStats} clicks
   */
  exports.prototype.clicks = undefined;

  /**
   * @member {module:model/AbTestVersionStats} unsubscribed
   */
  exports.prototype.unsubscribed = undefined;

  /**
   * @member {module:model/AbTestVersionStats} hardBounces
   */
  exports.prototype.hardBounces = undefined;

  /**
   * @member {module:model/AbTestVersionStats} softBounces
   */
  exports.prototype.softBounces = undefined;

  /**
   * @member {module:model/AbTestVersionStats} complaints
   */
  exports.prototype.complaints = undefined;


  return exports;

}));
