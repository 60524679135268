/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/GetExtendedCampaignOverview', 'model/GetExtendedCampaignOverviewSender', 'model/GetSmsCampaignRecipients'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./GetExtendedCampaignOverview'), require('./GetExtendedCampaignOverviewSender'), require('./GetSmsCampaignRecipients'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.GetEmailCampaign = factory(root.SibApiV3Sdk.ApiClient, root.SibApiV3Sdk.GetExtendedCampaignOverview, root.SibApiV3Sdk.GetExtendedCampaignOverviewSender, root.SibApiV3Sdk.GetSmsCampaignRecipients);
  }
}(this, function(ApiClient, GetExtendedCampaignOverview, GetExtendedCampaignOverviewSender, GetSmsCampaignRecipients) {
  'use strict';

  /**
   * The GetEmailCampaign model module.
   * @module model/GetEmailCampaign
   * @version 8.5.0
   */

  /**
   * Constructs a new <code>GetEmailCampaign</code>.
   * @alias module:model/GetEmailCampaign
   * @class
   * @extends module:model/GetExtendedCampaignOverview
   * @param id {Number} ID of the campaign
   * @param name {String} Name of the campaign
   * @param type {module:model/GetCampaignOverview.TypeEnum} Type of campaign
   * @param status {module:model/GetCampaignOverview.StatusEnum} Status of the campaign
   * @param testSent {Boolean} Retrieved the status of test email sending. (true=Test email has been sent  false=Test email has not been sent)
   * @param header {String} Header of the campaign
   * @param footer {String} Footer of the campaign
   * @param sender {module:model/GetExtendedCampaignOverviewSender} 
   * @param replyTo {String} Email defined as the \"Reply to\" of the campaign
   * @param htmlContent {String} HTML content of the campaign
   * @param createdAt {String} Creation UTC date-time of the campaign (YYYY-MM-DDTHH:mm:ss.SSSZ)
   * @param modifiedAt {String} UTC date-time of last modification of the campaign (YYYY-MM-DDTHH:mm:ss.SSSZ)
   * @param recipients {module:model/GetSmsCampaignRecipients} 
   * @param statistics {module:model/GetSmsCampaignRecipients} 
   */
  var exports = function(id, name, type, status, testSent, header, footer, sender, replyTo, htmlContent, createdAt, modifiedAt, recipients, statistics) {
    GetExtendedCampaignOverview.call(this, id, name, type, status, testSent, header, footer, sender, replyTo, htmlContent, createdAt, modifiedAt);
    this.recipients = recipients;
    this.statistics = statistics;
  };

  /**
   * Constructs a <code>GetEmailCampaign</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GetEmailCampaign} obj Optional instance to populate.
   * @return {module:model/GetEmailCampaign} The populated <code>GetEmailCampaign</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();
      GetExtendedCampaignOverview.constructFromObject(data, obj);
      if (data.hasOwnProperty('recipients'))
        obj.recipients = GetSmsCampaignRecipients.constructFromObject(data['recipients']);
      if (data.hasOwnProperty('statistics'))
        obj.statistics = GetSmsCampaignRecipients.constructFromObject(data['statistics']);
    }
    return obj;
  }

  exports.prototype = Object.create(GetExtendedCampaignOverview.prototype);
  exports.prototype.constructor = exports;
  /**
   * @member {module:model/GetSmsCampaignRecipients} recipients
   */
  exports.prototype.recipients = undefined;

  /**
   * @member {module:model/GetSmsCampaignRecipients} statistics
   */
  exports.prototype.statistics = undefined;


  return exports;

}));
