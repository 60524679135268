/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/AddContactToList', 'model/CreateAttribute', 'model/CreateContact', 'model/CreateDoiContact', 'model/CreateList', 'model/CreateModel', 'model/CreateUpdateContactModel', 'model/CreateUpdateFolder', 'model/CreatedProcessId', 'model/ErrorModel', 'model/GetAttributes', 'model/GetContactCampaignStats', 'model/GetContacts', 'model/GetExtendedContactDetails', 'model/GetExtendedList', 'model/GetFolder', 'model/GetFolderLists', 'model/GetFolders', 'model/GetLists', 'model/PostContactInfo', 'model/RemoveContactFromList', 'model/RequestContactExport', 'model/RequestContactImport', 'model/UpdateAttribute', 'model/UpdateBatchContacts', 'model/UpdateBatchContactsModel', 'model/UpdateContact', 'model/UpdateList'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('../model/AddContactToList'), require('../model/CreateAttribute'), require('../model/CreateContact'), require('../model/CreateDoiContact'), require('../model/CreateList'), require('../model/CreateModel'), require('../model/CreateUpdateContactModel'), require('../model/CreateUpdateFolder'), require('../model/CreatedProcessId'), require('../model/ErrorModel'), require('../model/GetAttributes'), require('../model/GetContactCampaignStats'), require('../model/GetContacts'), require('../model/GetExtendedContactDetails'), require('../model/GetExtendedList'), require('../model/GetFolder'), require('../model/GetFolderLists'), require('../model/GetFolders'), require('../model/GetLists'), require('../model/PostContactInfo'), require('../model/RemoveContactFromList'), require('../model/RequestContactExport'), require('../model/RequestContactImport'), require('../model/UpdateAttribute'), require('../model/UpdateBatchContacts'), require('../model/UpdateBatchContactsModel'), require('../model/UpdateContact'), require('../model/UpdateList'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.ContactsApi = factory(root.SibApiV3Sdk.ApiClient, root.SibApiV3Sdk.AddContactToList, root.SibApiV3Sdk.CreateAttribute, root.SibApiV3Sdk.CreateContact, root.SibApiV3Sdk.CreateDoiContact, root.SibApiV3Sdk.CreateList, root.SibApiV3Sdk.CreateModel, root.SibApiV3Sdk.CreateUpdateContactModel, root.SibApiV3Sdk.CreateUpdateFolder, root.SibApiV3Sdk.CreatedProcessId, root.SibApiV3Sdk.ErrorModel, root.SibApiV3Sdk.GetAttributes, root.SibApiV3Sdk.GetContactCampaignStats, root.SibApiV3Sdk.GetContacts, root.SibApiV3Sdk.GetExtendedContactDetails, root.SibApiV3Sdk.GetExtendedList, root.SibApiV3Sdk.GetFolder, root.SibApiV3Sdk.GetFolderLists, root.SibApiV3Sdk.GetFolders, root.SibApiV3Sdk.GetLists, root.SibApiV3Sdk.PostContactInfo, root.SibApiV3Sdk.RemoveContactFromList, root.SibApiV3Sdk.RequestContactExport, root.SibApiV3Sdk.RequestContactImport, root.SibApiV3Sdk.UpdateAttribute, root.SibApiV3Sdk.UpdateBatchContacts, root.SibApiV3Sdk.UpdateBatchContactsModel, root.SibApiV3Sdk.UpdateContact, root.SibApiV3Sdk.UpdateList);
  }
}(this, function(ApiClient, AddContactToList, CreateAttribute, CreateContact, CreateDoiContact, CreateList, CreateModel, CreateUpdateContactModel, CreateUpdateFolder, CreatedProcessId, ErrorModel, GetAttributes, GetContactCampaignStats, GetContacts, GetExtendedContactDetails, GetExtendedList, GetFolder, GetFolderLists, GetFolders, GetLists, PostContactInfo, RemoveContactFromList, RequestContactExport, RequestContactImport, UpdateAttribute, UpdateBatchContacts, UpdateBatchContactsModel, UpdateContact, UpdateList) {
  'use strict';

  /**
   * Contacts service.
   * @module api/ContactsApi
   * @version 8.5.0
   */

  /**
   * Constructs a new ContactsApi. 
   * @alias module:api/ContactsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * Add existing contacts to a list
     * @param {Number} listId Id of the list
     * @param {module:model/AddContactToList} contactEmails Emails addresses OR IDs of the contacts
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PostContactInfo} and HTTP response
     */
    this.addContactToListWithHttpInfo = function(listId, contactEmails) {
      var postBody = contactEmails;

      // verify the required parameter 'listId' is set
      if (listId === undefined || listId === null) {
        throw new Error("Missing the required parameter 'listId' when calling addContactToList");
      }

      // verify the required parameter 'contactEmails' is set
      if (contactEmails === undefined || contactEmails === null) {
        throw new Error("Missing the required parameter 'contactEmails' when calling addContactToList");
      }


      var pathParams = {
        'listId': listId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = PostContactInfo;

      return this.apiClient.callApi(
        '/contacts/lists/{listId}/contacts/add', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Add existing contacts to a list
     * @param {Number} listId Id of the list
     * @param {module:model/AddContactToList} contactEmails Emails addresses OR IDs of the contacts
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PostContactInfo}
     */
    this.addContactToList = function(listId, contactEmails) {
      return this.addContactToListWithHttpInfo(listId, contactEmails)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create contact attribute
     * @param {module:model/String} attributeCategory Category of the attribute
     * @param {String} attributeName Name of the attribute
     * @param {module:model/CreateAttribute} createAttribute Values to create an attribute
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.createAttributeWithHttpInfo = function(attributeCategory, attributeName, createAttribute) {
      var postBody = createAttribute;

      // verify the required parameter 'attributeCategory' is set
      if (attributeCategory === undefined || attributeCategory === null) {
        throw new Error("Missing the required parameter 'attributeCategory' when calling createAttribute");
      }

      // verify the required parameter 'attributeName' is set
      if (attributeName === undefined || attributeName === null) {
        throw new Error("Missing the required parameter 'attributeName' when calling createAttribute");
      }

      // verify the required parameter 'createAttribute' is set
      if (createAttribute === undefined || createAttribute === null) {
        throw new Error("Missing the required parameter 'createAttribute' when calling createAttribute");
      }


      var pathParams = {
        'attributeCategory': attributeCategory,
        'attributeName': attributeName
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/attributes/{attributeCategory}/{attributeName}', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create contact attribute
     * @param {module:model/String} attributeCategory Category of the attribute
     * @param {String} attributeName Name of the attribute
     * @param {module:model/CreateAttribute} createAttribute Values to create an attribute
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.createAttribute = function(attributeCategory, attributeName, createAttribute) {
      return this.createAttributeWithHttpInfo(attributeCategory, attributeName, createAttribute)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a contact
     * @param {module:model/CreateContact} createContact Values to create a contact
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreateUpdateContactModel} and HTTP response
     */
    this.createContactWithHttpInfo = function(createContact) {
      var postBody = createContact;

      // verify the required parameter 'createContact' is set
      if (createContact === undefined || createContact === null) {
        throw new Error("Missing the required parameter 'createContact' when calling createContact");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = CreateUpdateContactModel;

      return this.apiClient.callApi(
        '/contacts', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a contact
     * @param {module:model/CreateContact} createContact Values to create a contact
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreateUpdateContactModel}
     */
    this.createContact = function(createContact) {
      return this.createContactWithHttpInfo(createContact)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create Contact via DOI (Double-Opt-In) Flow
     * @param {module:model/CreateDoiContact} createDoiContact Values to create the Double opt-in (DOI) contact
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.createDoiContactWithHttpInfo = function(createDoiContact) {
      var postBody = createDoiContact;

      // verify the required parameter 'createDoiContact' is set
      if (createDoiContact === undefined || createDoiContact === null) {
        throw new Error("Missing the required parameter 'createDoiContact' when calling createDoiContact");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/doubleOptinConfirmation', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create Contact via DOI (Double-Opt-In) Flow
     * @param {module:model/CreateDoiContact} createDoiContact Values to create the Double opt-in (DOI) contact
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.createDoiContact = function(createDoiContact) {
      return this.createDoiContactWithHttpInfo(createDoiContact)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a folder
     * @param {module:model/CreateUpdateFolder} createFolder Name of the folder
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreateModel} and HTTP response
     */
    this.createFolderWithHttpInfo = function(createFolder) {
      var postBody = createFolder;

      // verify the required parameter 'createFolder' is set
      if (createFolder === undefined || createFolder === null) {
        throw new Error("Missing the required parameter 'createFolder' when calling createFolder");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = CreateModel;

      return this.apiClient.callApi(
        '/contacts/folders', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a folder
     * @param {module:model/CreateUpdateFolder} createFolder Name of the folder
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreateModel}
     */
    this.createFolder = function(createFolder) {
      return this.createFolderWithHttpInfo(createFolder)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create a list
     * @param {module:model/CreateList} createList Values to create a list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreateModel} and HTTP response
     */
    this.createListWithHttpInfo = function(createList) {
      var postBody = createList;

      // verify the required parameter 'createList' is set
      if (createList === undefined || createList === null) {
        throw new Error("Missing the required parameter 'createList' when calling createList");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = CreateModel;

      return this.apiClient.callApi(
        '/contacts/lists', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create a list
     * @param {module:model/CreateList} createList Values to create a list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreateModel}
     */
    this.createList = function(createList) {
      return this.createListWithHttpInfo(createList)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete an attribute
     * @param {module:model/String} attributeCategory Category of the attribute
     * @param {String} attributeName Name of the existing attribute
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.deleteAttributeWithHttpInfo = function(attributeCategory, attributeName) {
      var postBody = null;

      // verify the required parameter 'attributeCategory' is set
      if (attributeCategory === undefined || attributeCategory === null) {
        throw new Error("Missing the required parameter 'attributeCategory' when calling deleteAttribute");
      }

      // verify the required parameter 'attributeName' is set
      if (attributeName === undefined || attributeName === null) {
        throw new Error("Missing the required parameter 'attributeName' when calling deleteAttribute");
      }


      var pathParams = {
        'attributeCategory': attributeCategory,
        'attributeName': attributeName
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/attributes/{attributeCategory}/{attributeName}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete an attribute
     * @param {module:model/String} attributeCategory Category of the attribute
     * @param {String} attributeName Name of the existing attribute
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.deleteAttribute = function(attributeCategory, attributeName) {
      return this.deleteAttributeWithHttpInfo(attributeCategory, attributeName)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a contact
     * @param {String} identifier Email (urlencoded) OR ID of the contact
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.deleteContactWithHttpInfo = function(identifier) {
      var postBody = null;

      // verify the required parameter 'identifier' is set
      if (identifier === undefined || identifier === null) {
        throw new Error("Missing the required parameter 'identifier' when calling deleteContact");
      }


      var pathParams = {
        'identifier': identifier
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/{identifier}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a contact
     * @param {String} identifier Email (urlencoded) OR ID of the contact
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.deleteContact = function(identifier) {
      return this.deleteContactWithHttpInfo(identifier)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a folder (and all its lists)
     * @param {Number} folderId Id of the folder
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.deleteFolderWithHttpInfo = function(folderId) {
      var postBody = null;

      // verify the required parameter 'folderId' is set
      if (folderId === undefined || folderId === null) {
        throw new Error("Missing the required parameter 'folderId' when calling deleteFolder");
      }


      var pathParams = {
        'folderId': folderId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/folders/{folderId}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a folder (and all its lists)
     * @param {Number} folderId Id of the folder
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.deleteFolder = function(folderId) {
      return this.deleteFolderWithHttpInfo(folderId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a list
     * @param {Number} listId Id of the list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.deleteListWithHttpInfo = function(listId) {
      var postBody = null;

      // verify the required parameter 'listId' is set
      if (listId === undefined || listId === null) {
        throw new Error("Missing the required parameter 'listId' when calling deleteList");
      }


      var pathParams = {
        'listId': listId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/lists/{listId}', 'DELETE',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a list
     * @param {Number} listId Id of the list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.deleteList = function(listId) {
      return this.deleteListWithHttpInfo(listId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List all attributes
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetAttributes} and HTTP response
     */
    this.getAttributesWithHttpInfo = function() {
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetAttributes;

      return this.apiClient.callApi(
        '/contacts/attributes', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * List all attributes
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetAttributes}
     */
    this.getAttributes = function() {
      return this.getAttributesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a contact's details
     * Along with the contact details, this endpoint will show the statistics of contact for the recent 90 days by default. To fetch the earlier statistics, please use Get contact campaign stats (https://developers.sendinblue.com/reference/contacts-7#getcontactstats) endpoint with the appropriate date ranges.
     * @param {String} identifier Email (urlencoded) OR ID of the contact OR its SMS attribute value
     * @param {Object} opts Optional parameters
     * @param {Object} opts.startDate **Mandatory if endDate is used.** Starting date (YYYY-MM-DD) of the statistic events specific to campaigns. Must be lower than equal to endDate 
     * @param {Object} opts.endDate **Mandatory if startDate is used.** Ending date (YYYY-MM-DD) of the statistic events specific to campaigns. Must be greater than equal to startDate. 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetExtendedContactDetails} and HTTP response
     */
    this.getContactInfoWithHttpInfo = function(identifier, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'identifier' is set
      if (identifier === undefined || identifier === null) {
        throw new Error("Missing the required parameter 'identifier' when calling getContactInfo");
      }


      var pathParams = {
        'identifier': identifier
      };
      var queryParams = {
        'startDate': opts['startDate'],
        'endDate': opts['endDate'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetExtendedContactDetails;

      return this.apiClient.callApi(
        '/contacts/{identifier}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get a contact's details
     * Along with the contact details, this endpoint will show the statistics of contact for the recent 90 days by default. To fetch the earlier statistics, please use Get contact campaign stats (https://developers.sendinblue.com/reference/contacts-7#getcontactstats) endpoint with the appropriate date ranges.
     * @param {String} identifier Email (urlencoded) OR ID of the contact OR its SMS attribute value
     * @param {Object} opts Optional parameters
     * @param {Object} opts.startDate **Mandatory if endDate is used.** Starting date (YYYY-MM-DD) of the statistic events specific to campaigns. Must be lower than equal to endDate 
     * @param {Object} opts.endDate **Mandatory if startDate is used.** Ending date (YYYY-MM-DD) of the statistic events specific to campaigns. Must be greater than equal to startDate. 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetExtendedContactDetails}
     */
    this.getContactInfo = function(identifier, opts) {
      return this.getContactInfoWithHttpInfo(identifier, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get email campaigns' statistics for a contact
     * @param {String} identifier Email (urlencoded) OR ID of the contact
     * @param {Object} opts Optional parameters
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date (YYYY-MM-DD) of the statistic events specific to campaigns. Must be lower than equal to endDate
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date (YYYY-MM-DD) of the statistic events specific to campaigns. Must be greater than equal to startDate. Maximum difference between startDate and endDate should not be greater than 90 days
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetContactCampaignStats} and HTTP response
     */
    this.getContactStatsWithHttpInfo = function(identifier, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'identifier' is set
      if (identifier === undefined || identifier === null) {
        throw new Error("Missing the required parameter 'identifier' when calling getContactStats");
      }


      var pathParams = {
        'identifier': identifier
      };
      var queryParams = {
        'startDate': opts['startDate'],
        'endDate': opts['endDate'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetContactCampaignStats;

      return this.apiClient.callApi(
        '/contacts/{identifier}/campaignStats', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get email campaigns' statistics for a contact
     * @param {String} identifier Email (urlencoded) OR ID of the contact
     * @param {Object} opts Optional parameters
     * @param {String} opts.startDate Mandatory if endDate is used. Starting date (YYYY-MM-DD) of the statistic events specific to campaigns. Must be lower than equal to endDate
     * @param {String} opts.endDate Mandatory if startDate is used. Ending date (YYYY-MM-DD) of the statistic events specific to campaigns. Must be greater than equal to startDate. Maximum difference between startDate and endDate should not be greater than 90 days
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetContactCampaignStats}
     */
    this.getContactStats = function(identifier, opts) {
      return this.getContactStatsWithHttpInfo(identifier, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the contacts
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of documents per page (default to 50)
     * @param {Number} opts.offset Index of the first document of the page (default to 0)
     * @param {String} opts.modifiedSince Filter (urlencoded) the contacts modified after a given UTC date-time (YYYY-MM-DDTHH:mm:ss.SSSZ). Prefer to pass your timezone in date-time format for accurate result.
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetContacts} and HTTP response
     */
    this.getContactsWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'modifiedSince': opts['modifiedSince'],
        'sort': opts['sort'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetContacts;

      return this.apiClient.callApi(
        '/contacts', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the contacts
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of documents per page (default to 50)
     * @param {Number} opts.offset Index of the first document of the page (default to 0)
     * @param {String} opts.modifiedSince Filter (urlencoded) the contacts modified after a given UTC date-time (YYYY-MM-DDTHH:mm:ss.SSSZ). Prefer to pass your timezone in date-time format for accurate result.
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetContacts}
     */
    this.getContacts = function(opts) {
      return this.getContactsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get contacts in a list
     * @param {Number} listId Id of the list
     * @param {Object} opts Optional parameters
     * @param {String} opts.modifiedSince Filter (urlencoded) the contacts modified after a given UTC date-time (YYYY-MM-DDTHH:mm:ss.SSSZ). Prefer to pass your timezone in date-time format for accurate result.
     * @param {Number} opts.limit Number of documents per page (default to 50)
     * @param {Number} opts.offset Index of the first document of the page (default to 0)
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetContacts} and HTTP response
     */
    this.getContactsFromListWithHttpInfo = function(listId, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'listId' is set
      if (listId === undefined || listId === null) {
        throw new Error("Missing the required parameter 'listId' when calling getContactsFromList");
      }


      var pathParams = {
        'listId': listId
      };
      var queryParams = {
        'modifiedSince': opts['modifiedSince'],
        'limit': opts['limit'],
        'offset': opts['offset'],
        'sort': opts['sort'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetContacts;

      return this.apiClient.callApi(
        '/contacts/lists/{listId}/contacts', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get contacts in a list
     * @param {Number} listId Id of the list
     * @param {Object} opts Optional parameters
     * @param {String} opts.modifiedSince Filter (urlencoded) the contacts modified after a given UTC date-time (YYYY-MM-DDTHH:mm:ss.SSSZ). Prefer to pass your timezone in date-time format for accurate result.
     * @param {Number} opts.limit Number of documents per page (default to 50)
     * @param {Number} opts.offset Index of the first document of the page (default to 0)
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetContacts}
     */
    this.getContactsFromList = function(listId, opts) {
      return this.getContactsFromListWithHttpInfo(listId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Returns a folder's details
     * @param {Number} folderId id of the folder
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetFolder} and HTTP response
     */
    this.getFolderWithHttpInfo = function(folderId) {
      var postBody = null;

      // verify the required parameter 'folderId' is set
      if (folderId === undefined || folderId === null) {
        throw new Error("Missing the required parameter 'folderId' when calling getFolder");
      }


      var pathParams = {
        'folderId': folderId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetFolder;

      return this.apiClient.callApi(
        '/contacts/folders/{folderId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Returns a folder's details
     * @param {Number} folderId id of the folder
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetFolder}
     */
    this.getFolder = function(folderId) {
      return this.getFolderWithHttpInfo(folderId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get lists in a folder
     * @param {Number} folderId Id of the folder
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of documents per page (default to 10)
     * @param {Number} opts.offset Index of the first document of the page (default to 0)
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetFolderLists} and HTTP response
     */
    this.getFolderListsWithHttpInfo = function(folderId, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'folderId' is set
      if (folderId === undefined || folderId === null) {
        throw new Error("Missing the required parameter 'folderId' when calling getFolderLists");
      }


      var pathParams = {
        'folderId': folderId
      };
      var queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'sort': opts['sort'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetFolderLists;

      return this.apiClient.callApi(
        '/contacts/folders/{folderId}/lists', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get lists in a folder
     * @param {Number} folderId Id of the folder
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of documents per page (default to 10)
     * @param {Number} opts.offset Index of the first document of the page (default to 0)
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetFolderLists}
     */
    this.getFolderLists = function(folderId, opts) {
      return this.getFolderListsWithHttpInfo(folderId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all folders
     * @param {Number} limit Number of documents per page
     * @param {Number} offset Index of the first document of the page
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetFolders} and HTTP response
     */
    this.getFoldersWithHttpInfo = function(limit, offset, opts) {
      opts = opts || {};
      var postBody = null;

      // verify the required parameter 'limit' is set
      if (limit === undefined || limit === null) {
        throw new Error("Missing the required parameter 'limit' when calling getFolders");
      }

      // verify the required parameter 'offset' is set
      if (offset === undefined || offset === null) {
        throw new Error("Missing the required parameter 'offset' when calling getFolders");
      }


      var pathParams = {
      };
      var queryParams = {
        'limit': limit,
        'offset': offset,
        'sort': opts['sort'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetFolders;

      return this.apiClient.callApi(
        '/contacts/folders', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all folders
     * @param {Number} limit Number of documents per page
     * @param {Number} offset Index of the first document of the page
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetFolders}
     */
    this.getFolders = function(limit, offset, opts) {
      return this.getFoldersWithHttpInfo(limit, offset, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get a list's details
     * @param {Number} listId Id of the list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetExtendedList} and HTTP response
     */
    this.getListWithHttpInfo = function(listId) {
      var postBody = null;

      // verify the required parameter 'listId' is set
      if (listId === undefined || listId === null) {
        throw new Error("Missing the required parameter 'listId' when calling getList");
      }


      var pathParams = {
        'listId': listId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetExtendedList;

      return this.apiClient.callApi(
        '/contacts/lists/{listId}', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get a list's details
     * @param {Number} listId Id of the list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetExtendedList}
     */
    this.getList = function(listId) {
      return this.getListWithHttpInfo(listId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all the lists
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of documents per page (default to 10)
     * @param {Number} opts.offset Index of the first document of the page (default to 0)
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/GetLists} and HTTP response
     */
    this.getListsWithHttpInfo = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'limit': opts['limit'],
        'offset': opts['offset'],
        'sort': opts['sort'],
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = GetLists;

      return this.apiClient.callApi(
        '/contacts/lists', 'GET',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all the lists
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit Number of documents per page (default to 10)
     * @param {Number} opts.offset Index of the first document of the page (default to 0)
     * @param {module:model/String} opts.sort Sort the results in the ascending/descending order of record creation. Default order is **descending** if `sort` is not passed (default to desc)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/GetLists}
     */
    this.getLists = function(opts) {
      return this.getListsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Import contacts
     * It returns the background process ID which on completion calls the notify URL that you have set in the input.
     * @param {module:model/RequestContactImport} requestContactImport Values to import contacts in Sendinblue. To know more about the expected format, please have a look at ``https://help.sendinblue.com/hc/en-us/articles/209499265-Build-contacts-lists-for-your-email-marketing-campaigns``
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreatedProcessId} and HTTP response
     */
    this.importContactsWithHttpInfo = function(requestContactImport) {
      var postBody = requestContactImport;

      // verify the required parameter 'requestContactImport' is set
      if (requestContactImport === undefined || requestContactImport === null) {
        throw new Error("Missing the required parameter 'requestContactImport' when calling importContacts");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = CreatedProcessId;

      return this.apiClient.callApi(
        '/contacts/import', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Import contacts
     * It returns the background process ID which on completion calls the notify URL that you have set in the input.
     * @param {module:model/RequestContactImport} requestContactImport Values to import contacts in Sendinblue. To know more about the expected format, please have a look at ``https://help.sendinblue.com/hc/en-us/articles/209499265-Build-contacts-lists-for-your-email-marketing-campaigns``
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreatedProcessId}
     */
    this.importContacts = function(requestContactImport) {
      return this.importContactsWithHttpInfo(requestContactImport)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Delete a contact from a list
     * @param {Number} listId Id of the list
     * @param {module:model/RemoveContactFromList} contactEmails Emails addresses OR IDs of the contacts
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PostContactInfo} and HTTP response
     */
    this.removeContactFromListWithHttpInfo = function(listId, contactEmails) {
      var postBody = contactEmails;

      // verify the required parameter 'listId' is set
      if (listId === undefined || listId === null) {
        throw new Error("Missing the required parameter 'listId' when calling removeContactFromList");
      }

      // verify the required parameter 'contactEmails' is set
      if (contactEmails === undefined || contactEmails === null) {
        throw new Error("Missing the required parameter 'contactEmails' when calling removeContactFromList");
      }


      var pathParams = {
        'listId': listId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = PostContactInfo;

      return this.apiClient.callApi(
        '/contacts/lists/{listId}/contacts/remove', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Delete a contact from a list
     * @param {Number} listId Id of the list
     * @param {module:model/RemoveContactFromList} contactEmails Emails addresses OR IDs of the contacts
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PostContactInfo}
     */
    this.removeContactFromList = function(listId, contactEmails) {
      return this.removeContactFromListWithHttpInfo(listId, contactEmails)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Export contacts
     * It returns the background process ID which on completion calls the notify URL that you have set in the input. File will be available in csv.
     * @param {module:model/RequestContactExport} requestContactExport Values to request a contact export
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreatedProcessId} and HTTP response
     */
    this.requestContactExportWithHttpInfo = function(requestContactExport) {
      var postBody = requestContactExport;

      // verify the required parameter 'requestContactExport' is set
      if (requestContactExport === undefined || requestContactExport === null) {
        throw new Error("Missing the required parameter 'requestContactExport' when calling requestContactExport");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = CreatedProcessId;

      return this.apiClient.callApi(
        '/contacts/export', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Export contacts
     * It returns the background process ID which on completion calls the notify URL that you have set in the input. File will be available in csv.
     * @param {module:model/RequestContactExport} requestContactExport Values to request a contact export
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreatedProcessId}
     */
    this.requestContactExport = function(requestContactExport) {
      return this.requestContactExportWithHttpInfo(requestContactExport)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update contact attribute
     * @param {module:model/String} attributeCategory Category of the attribute
     * @param {String} attributeName Name of the existing attribute
     * @param {module:model/UpdateAttribute} updateAttribute Values to update an attribute
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.updateAttributeWithHttpInfo = function(attributeCategory, attributeName, updateAttribute) {
      var postBody = updateAttribute;

      // verify the required parameter 'attributeCategory' is set
      if (attributeCategory === undefined || attributeCategory === null) {
        throw new Error("Missing the required parameter 'attributeCategory' when calling updateAttribute");
      }

      // verify the required parameter 'attributeName' is set
      if (attributeName === undefined || attributeName === null) {
        throw new Error("Missing the required parameter 'attributeName' when calling updateAttribute");
      }

      // verify the required parameter 'updateAttribute' is set
      if (updateAttribute === undefined || updateAttribute === null) {
        throw new Error("Missing the required parameter 'updateAttribute' when calling updateAttribute");
      }


      var pathParams = {
        'attributeCategory': attributeCategory,
        'attributeName': attributeName
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/attributes/{attributeCategory}/{attributeName}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update contact attribute
     * @param {module:model/String} attributeCategory Category of the attribute
     * @param {String} attributeName Name of the existing attribute
     * @param {module:model/UpdateAttribute} updateAttribute Values to update an attribute
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.updateAttribute = function(attributeCategory, attributeName, updateAttribute) {
      return this.updateAttributeWithHttpInfo(attributeCategory, attributeName, updateAttribute)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update multiple contacts
     * @param {module:model/UpdateBatchContacts} updateBatchContacts Values to update multiple contacts
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.updateBatchContactsWithHttpInfo = function(updateBatchContacts) {
      var postBody = updateBatchContacts;

      // verify the required parameter 'updateBatchContacts' is set
      if (updateBatchContacts === undefined || updateBatchContacts === null) {
        throw new Error("Missing the required parameter 'updateBatchContacts' when calling updateBatchContacts");
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/batch', 'POST',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update multiple contacts
     * @param {module:model/UpdateBatchContacts} updateBatchContacts Values to update multiple contacts
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.updateBatchContacts = function(updateBatchContacts) {
      return this.updateBatchContactsWithHttpInfo(updateBatchContacts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a contact
     * @param {String} identifier Email (urlencoded) OR ID of the contact
     * @param {module:model/UpdateContact} updateContact Values to update a contact
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.updateContactWithHttpInfo = function(identifier, updateContact) {
      var postBody = updateContact;

      // verify the required parameter 'identifier' is set
      if (identifier === undefined || identifier === null) {
        throw new Error("Missing the required parameter 'identifier' when calling updateContact");
      }

      // verify the required parameter 'updateContact' is set
      if (updateContact === undefined || updateContact === null) {
        throw new Error("Missing the required parameter 'updateContact' when calling updateContact");
      }


      var pathParams = {
        'identifier': identifier
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/{identifier}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a contact
     * @param {String} identifier Email (urlencoded) OR ID of the contact
     * @param {module:model/UpdateContact} updateContact Values to update a contact
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.updateContact = function(identifier, updateContact) {
      return this.updateContactWithHttpInfo(identifier, updateContact)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a folder
     * @param {Number} folderId Id of the folder
     * @param {module:model/CreateUpdateFolder} updateFolder Name of the folder
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.updateFolderWithHttpInfo = function(folderId, updateFolder) {
      var postBody = updateFolder;

      // verify the required parameter 'folderId' is set
      if (folderId === undefined || folderId === null) {
        throw new Error("Missing the required parameter 'folderId' when calling updateFolder");
      }

      // verify the required parameter 'updateFolder' is set
      if (updateFolder === undefined || updateFolder === null) {
        throw new Error("Missing the required parameter 'updateFolder' when calling updateFolder");
      }


      var pathParams = {
        'folderId': folderId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/folders/{folderId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a folder
     * @param {Number} folderId Id of the folder
     * @param {module:model/CreateUpdateFolder} updateFolder Name of the folder
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.updateFolder = function(folderId, updateFolder) {
      return this.updateFolderWithHttpInfo(folderId, updateFolder)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Update a list
     * @param {Number} listId Id of the list
     * @param {module:model/UpdateList} updateList Values to update a list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    this.updateListWithHttpInfo = function(listId, updateList) {
      var postBody = updateList;

      // verify the required parameter 'listId' is set
      if (listId === undefined || listId === null) {
        throw new Error("Missing the required parameter 'listId' when calling updateList");
      }

      // verify the required parameter 'updateList' is set
      if (updateList === undefined || updateList === null) {
        throw new Error("Missing the required parameter 'updateList' when calling updateList");
      }


      var pathParams = {
        'listId': listId
      };
      var queryParams = {
      };
      var collectionQueryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var authNames = ['api-key', 'partner-key'];
      var contentTypes = ['application/json'];
      var accepts = ['application/json'];
      var returnType = null;

      return this.apiClient.callApi(
        '/contacts/lists/{listId}', 'PUT',
        pathParams, queryParams, collectionQueryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Update a list
     * @param {Number} listId Id of the list
     * @param {module:model/UpdateList} updateList Values to update a list
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    this.updateList = function(listId, updateList) {
      return this.updateListWithHttpInfo(listId, updateList)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
  };

  return exports;
}));
