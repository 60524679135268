/*
 * SendinBlue API
 * SendinBlue provide a RESTFul API that can be used with any languages. With this API, you will be able to :   - Manage your campaigns and get the statistics   - Manage your contacts   - Send transactional Emails and SMS   - and much more...  You can download our wrappers at https://github.com/orgs/sendinblue  **Possible responses**   | Code | Message |   | :-------------: | ------------- |   | 200  | OK. Successful Request  |   | 201  | OK. Successful Creation |   | 202  | OK. Request accepted |   | 204  | OK. Successful Update/Deletion  |   | 400  | Error. Bad Request  |   | 401  | Error. Authentication Needed  |   | 402  | Error. Not enough credit, plan upgrade needed  |   | 403  | Error. Permission denied  |   | 404  | Error. Object does not exist |   | 405  | Error. Method not allowed  |   | 406  | Error. Not Acceptable  | 
 *
 * OpenAPI spec version: 3.0.0
 * Contact: contact@sendinblue.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.26
 *
 * Do not edit the class manually.
 *
 */

(function(root, factory) {
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    define(['ApiClient', 'model/AbTestCampaignResultClickedLinks', 'model/AbTestCampaignResultStatistics'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // CommonJS-like environments that support module.exports, like Node.
    module.exports = factory(require('../ApiClient'), require('./AbTestCampaignResultClickedLinks'), require('./AbTestCampaignResultStatistics'));
  } else {
    // Browser globals (root is window)
    if (!root.SibApiV3Sdk) {
      root.SibApiV3Sdk = {};
    }
    root.SibApiV3Sdk.AbTestCampaignResult = factory(root.SibApiV3Sdk.ApiClient, root.SibApiV3Sdk.AbTestCampaignResultClickedLinks, root.SibApiV3Sdk.AbTestCampaignResultStatistics);
  }
}(this, function(ApiClient, AbTestCampaignResultClickedLinks, AbTestCampaignResultStatistics) {
  'use strict';

  /**
   * The AbTestCampaignResult model module.
   * @module model/AbTestCampaignResult
   * @version 8.5.0
   */

  /**
   * Constructs a new <code>AbTestCampaignResult</code>.
   * @alias module:model/AbTestCampaignResult
   * @class
   */
  var exports = function() {
  };

  /**
   * Constructs a <code>AbTestCampaignResult</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/AbTestCampaignResult} obj Optional instance to populate.
   * @return {module:model/AbTestCampaignResult} The populated <code>AbTestCampaignResult</code> instance.
   */
  exports.constructFromObject = function(data, obj) {
    if (data) {
      obj = obj || new exports();
      if (data.hasOwnProperty('winningVersion'))
        obj.winningVersion = ApiClient.convertToType(data['winningVersion'], 'String');
      if (data.hasOwnProperty('winningCriteria'))
        obj.winningCriteria = ApiClient.convertToType(data['winningCriteria'], 'String');
      if (data.hasOwnProperty('winningSubjectLine'))
        obj.winningSubjectLine = ApiClient.convertToType(data['winningSubjectLine'], 'String');
      if (data.hasOwnProperty('openRate'))
        obj.openRate = ApiClient.convertToType(data['openRate'], 'String');
      if (data.hasOwnProperty('clickRate'))
        obj.clickRate = ApiClient.convertToType(data['clickRate'], 'String');
      if (data.hasOwnProperty('winningVersionRate'))
        obj.winningVersionRate = ApiClient.convertToType(data['winningVersionRate'], 'String');
      if (data.hasOwnProperty('statistics'))
        obj.statistics = AbTestCampaignResultStatistics.constructFromObject(data['statistics']);
      if (data.hasOwnProperty('clickedLinks'))
        obj.clickedLinks = AbTestCampaignResultClickedLinks.constructFromObject(data['clickedLinks']);
    }
    return obj;
  }

  /**
   * Winning Campaign Info. pending = Campaign has been picked for sending and winning version is yet to be decided, tie = A tie happened between both the versions, notAvailable = Campaign has not yet been picked for sending.
   * @member {module:model/AbTestCampaignResult.WinningVersionEnum} winningVersion
   */
  exports.prototype.winningVersion = undefined;

  /**
   * Criteria choosen for winning version (Open/Click)
   * @member {module:model/AbTestCampaignResult.WinningCriteriaEnum} winningCriteria
   */
  exports.prototype.winningCriteria = undefined;

  /**
   * Subject Line of current winning version
   * @member {String} winningSubjectLine
   */
  exports.prototype.winningSubjectLine = undefined;

  /**
   * Open rate for current winning version
   * @member {String} openRate
   */
  exports.prototype.openRate = undefined;

  /**
   * Click rate for current winning version
   * @member {String} clickRate
   */
  exports.prototype.clickRate = undefined;

  /**
   * Open/Click rate for the winner version
   * @member {String} winningVersionRate
   */
  exports.prototype.winningVersionRate = undefined;

  /**
   * @member {module:model/AbTestCampaignResultStatistics} statistics
   */
  exports.prototype.statistics = undefined;

  /**
   * @member {module:model/AbTestCampaignResultClickedLinks} clickedLinks
   */
  exports.prototype.clickedLinks = undefined;



  /**
   * Allowed values for the <code>winningVersion</code> property.
   * @enum {String}
   * @readonly
   */
  exports.WinningVersionEnum = {
    /**
     * value: "notAvailable"
     * @const
     */
    notAvailable: "notAvailable",

    /**
     * value: "pending"
     * @const
     */
    pending: "pending",

    /**
     * value: "tie"
     * @const
     */
    tie: "tie",

    /**
     * value: "A"
     * @const
     */
    A: "A",

    /**
     * value: "B"
     * @const
     */
    B: "B"
  };


  /**
   * Allowed values for the <code>winningCriteria</code> property.
   * @enum {String}
   * @readonly
   */
  exports.WinningCriteriaEnum = {
    /**
     * value: "Open"
     * @const
     */
    open: "Open",

    /**
     * value: "Click"
     * @const
     */
    click: "Click"
  };

  return exports;

}));
